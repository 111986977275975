import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Pagination } from 'src/app/domain/pagination';
import { CarRefuel } from 'src/app/domain/car-refuel';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { CarRefuelService } from 'src/app/service/car-refuel/car-refuel.service';
import { Car } from 'src/app/domain/car';
import { CarService } from 'src/app/service/car/car.service';
import { GasStation } from 'src/app/domain/gas-station';
import { GasStationService } from 'src/app/service/gas-station/gas-station.service';
import { Validations } from '../../validations';
import { Driver } from 'src/app/domain/driver';
import { SupplyTypeEnum } from 'src/app/enumerations/supply-type.enum';
import { CarFuelEnum } from 'src/app/enumerations/car-fuel.enum';
import { SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-car-refuel',
  templateUrl: './car-refuel.component.html',
  styleUrls: ['./car-refuel.component.scss'],
})
export class CarRefuelComponent implements OnInit {
  car: Car = new Car();
  carRefuelForm: FormGroup;
  searchCarRefuelForm: FormGroup;
  loading: boolean = false;
  messageError: string = '';
  pagination: Pagination = new Pagination();
  carId: number;
  refuels: Array<CarRefuel> = [];
  gasStations: Array<GasStation> = [];
  first: boolean = false;
  last: boolean = false;
  drivers: Driver[] = [];

  image: File;

  inUpload: { id: number; imageUrl: string | ArrayBuffer } = {
    id: null,
    imageUrl: null,
  };

  constructor(
    public readonly swalTargets: SwalPortalTargets,
    private service: CarRefuelService,
    private serviceCar: CarService,
    private serviceGasStation: GasStationService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      this.carId = params['id'];
    });

    this.route.queryParams.subscribe((params) => {
      this.pagination.per_page = params.per_page || 10;
      this.pagination.page = params.page || 1;
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.createFormSearch();
    this.getRefuels();
    this.getGasStations();
    this.getCar();
    this.getDrivers();
  }

  getFuelType(fuelType: string) {
    switch (fuelType) {
      case CarFuelEnum.alcool: {
        return 'Álcool';
      }

      case CarFuelEnum.diesel: {
        return 'Diesel';
      }

      case CarFuelEnum.flex: {
        return 'Flex';
      }

      case CarFuelEnum.gasolina: {
        return 'Gasolina';
      }

      case CarFuelEnum.gnv: {
        return 'Gás Natural';
      }

      default: {
        return 'Campo Inválido';
      }
    }
  }

  // ---------------- FUNÇÕES DE LISTAGEM DE ABASTECIMENTOS --------------------
  getRefuels() {
    this.loading = true;
    this.service
      .getCarRefuels(
        this.carId,
        this.searchCarRefuelForm.getRawValue(),
        this.pagination
      )
      .subscribe(
        (data) => {
          this.refuels = data['results'];
          this.setPagination(data);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.openAlertError();
        }
      );
  }

  getGasStations() {
    this.loading = true;
    this.serviceGasStation.getGasStationsSelect().subscribe(
      (data) => {
        this.gasStations = data['results'];
        this.setPagination(data);
      },
      (error) => {
        Swal.fire(
          'Erro ao carregar dados',
          'Erro ao buscar dados de Posto de Combustível!',
          'error'
        );
      }
    );
  }

  getDrivers() {
    this.serviceCar.getDriversByCar(this.carId).subscribe({
      next: (data) => (this.drivers = data),
      error: () =>
        Swal.fire({
          title: 'Erro ao carregar dados',
          text: 'Erro ao buscar os motoristas',
          icon: 'error',
        }),
    });
  }

  getCar() {
    this.serviceCar.getCar(this.carId).subscribe(
      (data) => {
        this.car = data;
      },
      (error) => {
        Swal.fire(
          'Erro ao carregar dados',
          'Erro ao buscar dados do veículo!',
          'error'
        );
      }
    );
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? (this.first = false) : (this.first = true);
    data['totalPages'] <= this.pagination.page
      ? (this.last = false)
      : (this.last = true);
  }

  onFirst() {
    this.pagination.page = 1;
    this.getGasStations();
  }
  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.getGasStations();
  }
  onPrevious() {
    this.pagination.page--;
    this.getGasStations();
  }
  onNext() {
    this.pagination.page++;
    this.getGasStations();
  }

  openAlertError() {
    Swal.fire(
      'Ops...',
      'Ocorreu um erro ao carregar os abastecimentos!',
      'error'
    );
  }
  // ---------------- FUNÇÕES DE LISTAGEM DE ABASTECIMENTOS --------------------

  // ---------------- FUNÇÕES DE CADASTRO e EDIÇÃO DE ABASTECIMENTOS --------------------
  createForm() {
    this.carRefuelForm = this.fb.group({
      id: [null],
      gasStation: ['', [Validators.required]],
      fuel: ['', [Validators.required]],
      date: ['', [Validators.required, , Validations.validDateMoreThanToday]],
      hour: ['', [Validators.required]],
      mileage: ['', [Validators.required, Validators.maxLength(6)]],
      numberOfLiters: ['', [Validators.required]],
      value: ['', [Validators.required, Validators.maxLength(10)]],
      invoiceNumber: ['', [Validators.required, Validators.maxLength(20)]],
      driver: ['', [Validators.required]],
      type: ['', [Validators.required]],
    });
  }
  createFormSearch() {
    this.searchCarRefuelForm = this.fb.group({
      start_date: ['', []],
      end_date: ['', []],
      gasStationId: ['', []],
    });
  }

  onSubmit() {
    if (!this.loading) {
      try {
        this.loading = true;
        const values = this.carRefuelForm.controls;
        let carRefuel = new CarRefuel();
        carRefuel.id = +values.id.value;
        carRefuel.gasStation = new GasStation();
        carRefuel.gasStation.id = values.gasStation.value;
        carRefuel.fuel = CarFuelEnum[values.fuel.value];
        carRefuel.date = values.date.value;
        carRefuel.hour = values.hour.value;
        carRefuel.mileage = values.mileage.value;
        carRefuel.numberOfLiters = values.numberOfLiters.value;
        carRefuel.value = values.value.value;
        carRefuel.invoiceNumber = values.invoiceNumber.value;
        carRefuel.driver = new Driver();
        carRefuel.driver.id = values.driver.value;
        carRefuel.supplyType = SupplyTypeEnum[values.type.value];

        this.messageError = '';
        if (!carRefuel.id) {
          this.service.save(this.carId, carRefuel).subscribe(
            (data) => {
              this.loading = false;
              Swal.fire(
                'Salvo',
                'Abastecimento salvo com sucesso!!',
                'success'
              );
              this.createForm();
              this.getRefuels();
            },
            (error) => {
              this.loading = false;
              this.setErrors(error.error.errors);
              Swal.fire(
                'Erro ao salvar',
                'Não foi possível salvar Abastecimento!',
                'error'
              );
            }
          );
        } else {
          this.service.update(this.carId, carRefuel).subscribe(
            (data) => {
              this.loading = false;
              Swal.fire(
                'Salvo',
                'Abastecimento salvo com sucesso!!',
                'success'
              );
              this.createForm();
              this.getRefuels();
            },
            (error) => {
              this.loading = false;
              this.setErrors(error.error.errors);
              Swal.fire(
                'Erro ao salvar',
                'Não foi possível salvar Abastecimento!',
                'error'
              );
            }
          );
        }
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    }
  }

  onRefuelDelete(carRefuel) {
    Swal.fire({
      title: 'Excluir abastecimento',
      text: 'Deseja realmente excluir o abastecimento?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.delete(this.carId, carRefuel).subscribe(
          (data) => {
            this.loading = false;
            Swal.fire(
              'Excluido!',
              'Abastecimento excluído com sucesso!!',
              'success'
            );
            this.createForm();
            this.getRefuels();
          },
          (error) => {
            console.log(error);
            this.loading = false;
            this.setErrors(error.error.errors);
            Swal.fire(
              'Erro ao excluir',
              'Não foi possível excluir Abastecimento!',
              'error'
            );
          }
        );
      }
    });
  }

  resetForm() {
    this.carRefuelForm.reset();
  }

  setErrors(errors) {
    errors.forEach((erro) => {
      switch (erro.fieldName) {
        case 'gasStation':
          this.carRefuelForm.controls.gasStation.setErrors({
            invalid: erro.message,
          });
          break;
        case 'fuel':
          this.carRefuelForm.controls.fuel.setErrors({ invalid: erro.message });
          break;
        case 'date':
          this.carRefuelForm.controls.date.setErrors({ invalid: erro.message });
          break;
        case 'hour':
          this.carRefuelForm.controls.hour.setErrors({ invalid: erro.message });
          break;
        case 'mileage':
          this.carRefuelForm.controls.mileage.setErrors({
            invalid: erro.message,
          });
          break;
        case 'numberOfLiters':
          this.carRefuelForm.controls.numberOfLiters.setErrors({
            invalid: erro.message,
          });
          break;
        case 'value':
          this.carRefuelForm.controls.value.setErrors({
            invalid: erro.message,
          });
          break;
        case 'invoiceNumber':
          this.carRefuelForm.controls.invoiceNumber.setErrors({
            invalid: erro.message,
          });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });
  }

  openFormDialog(id: number) {
    this.inUpload.id = id;
  }

  onRemoveImage(event) {
    this.image = null;
    this.inUpload.imageUrl = null;
  }

  onSelectImage(event) {
    const file: File = event.addedFiles[0];
    if (file.size > 10000000) {
      this.image = null;
      Swal.fire('Ops...', 'A imagem não pode ser maior que 10MB!', 'error');
    } else {
      this.image = file;
      this.onFileReader();
    }
  }

  onFileReader() {
    const reader = new FileReader();
    reader.readAsDataURL(this.image);
    reader.onload = () => (this.inUpload.imageUrl = reader.result);
  }

  onFileUpload() {
    if (this.image) {
      this.service
        .imageUpload(this.inUpload.id, this.image)
        .subscribe((data) => {
          this.loading = false;
          this.showSuccessUpload();
        }),
        (error) => {
          this.loading = false;
          Swal.fire('Erro', 'Erro ao salvar comprovante!', 'error');
        };
    } else {
      this.loading = false;
      Swal.fire('Erro', 'Nenhum comprovante foi carregado!', 'error');
    }
  }

  onCancelFileUpload(reason: Swal.DismissReason | undefined) {
    this.image = null;
    this.inUpload = { id: null, imageUrl: null };
    console.log(reason);
  }

  showSuccessUpload() {
    Swal.fire('Salvo', 'Comprovante salvo com sucesso!!', 'success').then(
      (result) => {
        if (result.value) {
          this.image = null;
          this.inUpload = { id: null, imageUrl: null };
          this.getRefuels();
        }
      }
    );
  }

  onFileRefuelDelete(carRefuel) {
    Swal.fire({
      title: 'Excluir comprovante de abastecimento',
      text: 'Deseja realmente excluir o comprovante de abastecimento?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.fileDelete(carRefuel).subscribe(
          (data) => {
            this.loading = false;
            Swal.fire(
              'Excluido!',
              'Comprovante de abastecimento excluído com sucesso!!',
              'success'
            );
            this.createForm();
            this.getRefuels();
          },
          (error) => {
            console.log(error);
            this.loading = false;
            this.setErrors(error.error.errors);
            Swal.fire(
              'Erro ao excluir',
              'Não foi possível excluir o comprovante de bastecimento!',
              'error'
            );
          }
        );
      }
    });
  }

  editRefuel(reful) {
    this.carRefuelForm.patchValue({
      ...reful,
      gasStation: reful.gasStationId,
      fuel:
        Object.keys(CarFuelEnum)[
          Object.values(CarFuelEnum).indexOf(reful.fuel)
        ] || null,
      driver: reful.driverId,
      type:
        Object.keys(SupplyTypeEnum)[
          Object.values(SupplyTypeEnum).indexOf(reful.supplyType)
        ] || null,
    });
  }
}
