import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Collaborator } from 'src/app/domain/collaborator';
import { CollaboratorDTO } from 'src/app/domain/dto/collaborator.dto';
import { Pagination } from 'src/app/domain/pagination';

@Injectable({
  providedIn: 'root',
})
export class CovenantCollaboratorService {
  url: string = `${environment.BASE_URL}/covenants`;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  });

  constructor(private http: HttpClient) {}

  getCollaborators(
    id: number,
    pagination: Pagination,
    collaborator?: Collaborator
  ) {
    const params = {
      page: `${pagination.page ?? 1}`,
      per_page: `${pagination.per_page ?? 10}`,
      active: `${collaborator?.active ?? ''}`,
      name: `${collaborator?.name ?? ''}`,
      whatsapp: `${collaborator?.whatsapp ?? ''}`,
      role: `${collaborator?.role ?? ''}`,
    };

    return this.http.get<Collaborator>(`${this.url}/${id}/collaborators`, {
      responseType: 'json',
      params: params,
    });
  }

  exportCollaborators(id: number, type: string, collaborator?: Collaborator) {
    const params = {
      active: `${collaborator?.active ?? ''}`,
      name: `${collaborator?.name ?? ''}`,
      role: `${collaborator?.role ?? ''}`,
      whatsapp: `${collaborator?.whatsapp ?? ''}`,
      type: type,
    };

    return this.http.get(`${this.url}/${id}/collaborators/export`, {
      headers: this.httpHeaders,
      responseType: 'blob',
      params: params,
      observe: 'response',
    });
  }

  save(covenantId: number, collaborator: Collaborator) {
    let dto = new CollaboratorDTO(collaborator);
    return this.http.post(
      `${this.url}/${covenantId}/collaborators`,
      JSON.stringify(dto),
      {
        headers: this.httpHeaders,
        observe: 'response',
      }
    );
  }

  update(covenantId: number, collaborator: Collaborator) {
    let dto = new CollaboratorDTO(collaborator);
    console.log(JSON.stringify(dto));
    return this.http.put(
      `${this.url}/${covenantId}/collaborators/${collaborator.id}`,
      JSON.stringify(dto),
      {
        headers: this.httpHeaders,
        observe: 'response',
      }
    );
  }

  activeColaborattor(covenantId: number, collaboratorId: number) {
    return this.http.put(
      `${this.url}/${covenantId}/collaborators/${collaboratorId}/active`,
      null,
      {
        headers: this.httpHeaders,
        observe: 'response',
      }
    );
  }

  deactiveColaborattor(covenantId: number, collaboratorId: number) {
    return this.http.put(
      `${this.url}/${covenantId}/collaborators/${collaboratorId}/deactive`,
      null,
      {
        headers: this.httpHeaders,
        observe: 'response',
      }
    );
  }

  delete(covenantId: number, collaboratorId: number) {
    return this.http.delete(
      `${this.url}/${covenantId}/collaborators/${collaboratorId}`,
      {
        headers: this.httpHeaders,
        observe: 'response',
      }
    );
  }
}
