import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Address } from 'src/app/domain/address';
import { Car } from 'src/app/domain/car';
import { City } from 'src/app/domain/city';
import { CostCenter } from 'src/app/domain/cost-center';
import { Driver } from 'src/app/domain/driver';
import { PaymentMethod } from 'src/app/domain/paymentMethod';
import { RequestValue } from 'src/app/domain/request-value';
import { State } from 'src/app/domain/state';
import { AddressService } from 'src/app/service/address/address.service';
import { CarService } from 'src/app/service/car/car.service';
import { ClientService } from 'src/app/service/client/client.service';
import { CostCenterService } from 'src/app/service/cost-center/cost-center.service';
import { CovenantService } from 'src/app/service/covenant/covenant.service';
import { PaymentMethodsService } from 'src/app/service/payment-methods/payment-methods.service';
import { RequestValueService } from 'src/app/service/request-value/request-value.service';
import { UtilsService } from 'src/app/service/utils/utils.service';
import Swal from 'sweetalert2';
import moment from 'moment';
import { ValidationsTripRequest } from '../../trip-request/validations/validations-trip-request';
import { Validations } from '../../validations';
import { DestinyPoint } from 'src/app/domain/destiny-point';
import { RunService } from 'src/app/service/run/run.service';
import { Collaborator } from 'src/app/domain/collaborator';

@Component({
  selector: 'app-runs-update',
  templateUrl: './runs-update.component.html',
  styleUrls: ['./runs-update.component.scss'],
})
export class RunsUpdateComponent implements OnInit {
  runId: number;
  run;

  nameTrip: String = '';

  runForm: FormGroup;
  searchClientForm: FormGroup;
  loading: boolean;
  states: Array<State> = [];
  originCities: Array<City> = [];
  destinyCities: Array<City> = [];
  paymentMethods: Array<PaymentMethod> = [];
  clientAddress: Address;
  covenantAddress: Address;
  costCenters: Array<CostCenter> = [];
  cars: Array<Car> = [];
  drivers: Array<Driver> = [];
  covenantId: number;
  clientId: number;
  requestValues: Array<RequestValue> = [];
  destinyPoints: Array<DestinyPoint> = [];
  messageError: string = '';
  collaborators: Collaborator[];
  cities: Array<Array<City>> = [];
  selectDestinyCities: Array<Array<City>> = [];

  constructor(
    private fb: FormBuilder,
    private utilsService: UtilsService,
    private service: RunService,
    private serviceAddress: AddressService,
    private serviceClient: ClientService,
    private serviceCovenant: CovenantService,
    private serviceCostCenter: CostCenterService,
    private carService: CarService,
    private serviceRequestValue: RequestValueService,
    private paymentMethodService: PaymentMethodsService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      this.runId = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.createClientForm();
    this.disableForms();
    this.getStates();
    this.getAllCostCenters();
    this.getTripRequest();
  }

  changePassenger(e) {
    this.resetDataForm();
    this.searchClientForm.controls.searchClient.setValue('');
    this.originCities = [];
    this.destinyCities = [];
  }

  getTripRequest() {
    this.service.getRun(this.runId).subscribe(
      (data) => {
        this.run = data;
        this.run.passengers = this.run.runPassengers;
        this.loading = false;

        if (this.run.status === 'paid') {
          this.runForm.get('paymentMethod').disable();
        }
        this.getAllCars();
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível carregar a corrida!', 'error');
      }
    );
  }

  populateForms() {
    this.runForm.controls.covenantCode.setValue(this.run.covenantCode);
    const { name, phone, whatsapp } =
      this.run.runRequest.client ?? this.run.runRequest.covenant;

    if (this.run.runRequest.clientId) {
      this.runForm.controls.passenger.setValue('client');
      this.getClientSearch();
    } else {
      this.runForm.controls.passenger.setValue('covenant');
      this.getCovenantSearch();
    }
    this.runForm.controls.name.setValue(name);
    this.runForm.controls.phone.setValue(phone);
    this.runForm.controls.whatsapp.setValue(whatsapp);
    this.runForm.controls.car.setValue(this.run.vehicleId);
    this.getDriversByCar();
    this.runForm.controls.startHour.setValue(this.run.startHour);
    this.runForm.controls.driver.setValue(this.run.driverId);
    this.runForm.controls.startHour.setValue(this.run.startHour);
    this.runForm.controls.endHour.setValue(this.run.endHour);
    this.runForm.controls.paymentMethod.setValue(
      this.run.paymentMethodId ?? this.run.runRequest.paymentMethodId
    );
    this.runForm.controls.costCenterId.setValue(
      this.run.costCenterId ?? this.run.runRequest.costCenterId
    );
    this.runForm.controls.requestValueId.setValue(
      this.run.requestValueId ?? this.run.runRequest.requestValueId
    );
    this.runForm.controls.date.setValue(
      moment(this.run.date).format('YYYY-MM-DD')
    );
    this.runForm.controls.observation.setValue(
      this.run.observation ?? this.run.runRequest.observation
    );
    this.runForm.controls.runOtherValue.setValue(this.run.runOtherValue);
    this.runForm.controls.runDiscount.setValue(this.run.runDiscount);
  }

  resetDataForm() {
    this.runForm.controls.covenantId.setValue('');
    this.runForm.controls.clientId.setValue('');
    this.runForm.controls.name.setValue('');
    this.runForm.controls.whatsapp.setValue('');
    this.runForm.controls.phone.setValue('');
    this.runForm.controls.startHour.setValue('');
    this.runForm.controls.endHour.setValue('');
    this.runForm.controls.costCenterId.setValue('');
    this.runForm.controls.collaborator.setValue('');
    this.runForm.controls.otherAddress.setValue('');
    this.runForm.controls.car.setValue('');
    this.runForm.controls.cost.setValue('');
    this.runForm.controls.requestValueId.setValue('');
    this.runForm.controls.observation.setValue('');
    this.runForm.controls.recursive.setValue(false);
    this.runForm.controls.allDays.setValue(false);
    this.runForm.controls.sunday.setValue(false);
    this.runForm.controls.monday.setValue(false);
    this.runForm.controls.tuesday.setValue(false);
    this.runForm.controls.wednesday.setValue(false);
    this.runForm.controls.thursday.setValue(false);
    this.runForm.controls.friday.setValue(false);
    this.runForm.controls.saturday.setValue(false);
    this.runForm.controls.passengers.setValue([]);
    this.runForm.controls.paymentMethod.setValue('');
    this.runForm.controls.runOtherValue.setValue('');
    this.runForm.controls.runDiscount.setValue('');
  }

  getAllCostCenters() {
    this.serviceCostCenter.getAllCostCenters().subscribe((data) => {
      this.costCenters = data;
    });
  }
  getAllCars() {
    this.loading = true;
    this.carService.getCarsActives().subscribe(
      (data) => {
        this.cars = data;
        for (const car of this.cars)
          car.nameCarPlate = `${car.model.name} ${car.carPlate}`;
        this.populateForms();
      },
      (error) => (this.loading = false)
    );
  }

  createForm() {
    this.runForm = this.fb.group({
      covenantCode: ['', [Validators.max(999999999999999)]],
      passenger: ['', [Validators.required]],
      name: ['', [Validators.required]],
      whatsapp: ['', [Validations.validwhatsapp]],
      phone: ['', [Validations.validPhone]],
      date: [this.getAtualDate(), Validators.required],
      startHour: ['', [Validators.required]],
      endHour: ['', [Validators.required]],
      costCenterId: ['', [Validators.required]],
      covenantId: [''],
      clientId: [''],
      collaborator: [''],
      otherAddress: [''],
      otherDestinyAddress: [''],
      car: [''],
      driver: [''],
      requestValueId: ['', [Validators.required]],
      destinyPoint: ['', [ValidationsTripRequest.validaClient]],
      observation: [''],
      passengers: this.fb.array([]),
      paymentMethod: ['', [Validators.required]],
      originAddress: this.fb.group({
        id: [''],
        zipcode: ['', [ValidationsTripRequest.validaClient]],
        publicPlace: ['', [ValidationsTripRequest.validaClient]],
        neighborhood: ['', [ValidationsTripRequest.validaClient]],
        state: ['', [ValidationsTripRequest.validaClient]],
        city: ['', [ValidationsTripRequest.validaClient]],
      }),
      destinyAddress: this.fb.group({
        id: [''],
        zipcode: ['', [ValidationsTripRequest.validaClient]],
        publicPlace: ['', [ValidationsTripRequest.validaClient]],
        neighborhood: ['', [ValidationsTripRequest.validaClient]],
        state: ['', [ValidationsTripRequest.validaClient]],
        city: ['', [ValidationsTripRequest.validaClient]],
      }),
      runOtherValue: [''],
      runDiscount: [''],
    });
  }

  onGetAndAddCollaborator(id) {
    this.onAddCollaborator(this.collaborators.find((e) => e.id === id));
  }

  onAddCollaborator(collaborator) {
    const collaboratorsAdd = this.passengers.value.filter(
      (elem) => elem['id'] == collaborator.id
    );

    if (collaboratorsAdd.length == 0) {
      this.passengers.push(this.newCollaborator(collaborator));

      const i = this.passengers.length - 1;

      this.passengers['controls'][i]['controls'].main.setValue(
        collaborator.isMain
      );
      this.passengers['controls'][i]['controls'].destinyPoint.setValue(
        collaborator.destinyPoint?.id
      );

      if (this.covenantAddress.zipCode === collaborator.origin?.zipCode) {
        this.passengers['controls'][i]['controls'].covenantAddress.setValue(
          true
        );
        this.passengers['controls'][i]['controls'].otherAddress.setValue(false);
        this.passengers['controls'][i]['controls'].clientAddress.setValue(
          false
        );
        this.passengers['controls'][i]['controls'].address.disable();
      } else if (
        collaborator.origin?.zipCode === collaborator.address.zipCode
      ) {
        this.passengers['controls'][i]['controls'].covenantAddress.setValue(
          false
        );
        this.passengers['controls'][i]['controls'].otherAddress.setValue(false);
        this.passengers['controls'][i]['controls'].clientAddress.setValue(true);
        this.passengers['controls'][i]['controls'].address.disable();
      } else {
        this.passengers['controls'][i]['controls'].covenantAddress.setValue(
          false
        );
        this.passengers['controls'][i]['controls'].otherAddress.setValue(true);
        this.passengers['controls'][i]['controls'].clientAddress.setValue(
          false
        );
      }

      if (this.covenantAddress.zipCode === collaborator.destiny?.zipCode) {
        this.passengers['controls'][i][
          'controls'
        ].covenantDestinyAddress.setValue(true);
        this.passengers['controls'][i]['controls'].otherDestinyAddress.setValue(
          false
        );
        this.passengers['controls'][i][
          'controls'
        ].clientDestinyAddress.setValue(false);
        this.passengers['controls'][i]['controls'].destinyAddress.disable();
      } else if (
        collaborator.destiny?.zipCode === collaborator.address.zipCode
      ) {
        this.passengers['controls'][i][
          'controls'
        ].covenantDestinyAddress.setValue(false);
        this.passengers['controls'][i]['controls'].otherDestinyAddress.setValue(
          false
        );
        this.passengers['controls'][i][
          'controls'
        ].clientDestinyAddress.setValue(true);
        this.passengers['controls'][i]['controls'].destinyAddress.disable();
      } else {
        this.passengers['controls'][i][
          'controls'
        ].covenantDestinyAddress.setValue(false);
        this.passengers['controls'][i]['controls'].otherDestinyAddress.setValue(
          true
        );
        this.passengers['controls'][i][
          'controls'
        ].clientDestinyAddress.setValue(false);
      }

      this.runForm.controls.collaborator.setValue('');
    } else {
      Swal.fire(
        'Atenção',
        'Colaborador já foi adicionado à Corrida',
        'warning'
      );
    }
  }
  removeCollaborator(i: number) {
    this.passengers.removeAt(i);
  }

  newCollaborator(collaborator): FormGroup {
    if (collaborator.address)
      this.serviceAddress
        .getCitiesByStateId(collaborator.address.city.state.id)
        .subscribe((data) => {
          this.cities.push(data);
          this.selectDestinyCities.push(data);
        });

    return this.fb.group({
      id: [collaborator.id],
      name: collaborator.name,
      phone: collaborator.phone,
      whatsapp: collaborator.whatsapp,
      costCenter: collaborator.costCenter,
      main: false,
      destinyPoint: '',
      otherAddress: false,
      covenantAddress: false,
      clientAddress: true,
      otherDestinyAddress: true,
      covenantDestinyAddress: false,
      clientDestinyAddress: false,
      address: this.fb.group({
        id: [collaborator.origin?.id],
        zipcode: [collaborator.origin?.zipCode, [Validators.maxLength(9)]],
        publicPlace: [collaborator.origin?.publicPlace, []],
        neighborhood: [collaborator.origin?.neighborhood, []],
        state: [collaborator.origin?.city.state.id, []],
        city: [collaborator.origin?.city.id, []],
      }),
      destinyAddress: this.fb.group({
        id: [collaborator.destiny?.id],
        zipcode: [collaborator.destiny?.zipCode, [Validators.maxLength(9)]],
        publicPlace: [collaborator.destiny?.publicPlace, []],
        neighborhood: [collaborator.destiny?.neighborhood, []],
        state: [collaborator.destiny?.city.state.id, []],
        city: [collaborator.destiny?.city.id, []],
      }),
    });
  }

  selectRequestValue() {
    const requestValue = this.requestValues.find(
      (elem) => this.runForm.controls.requestValueId.value == elem.id
    );
    this.destinyPoints = requestValue ? requestValue.destinyPoints : [];
  }

  get passengers() {
    return this.runForm.get('passengers') as FormArray;
  }

  onChangeScheduded(e) {
    if (e.target.checked) {
      this.runForm.controls.scheduled.setValue(true);
      this.runForm.controls.date.setValue(this.getAtualDate());
    } else {
      this.runForm.controls.scheduled.setValue(false);
    }
  }
  onClickCovenantAddress(e, i) {
    if (e.target.checked) {
      this.passengers['controls'][i]['controls'].address.disable();
      this.passengers['controls'][i]['controls'].covenantAddress.setValue(true);
      this.passengers['controls'][i]['controls'].otherAddress.setValue(false);
      this.passengers['controls'][i]['controls'].clientAddress.setValue(false);
      this.passengers['controls'][i]['controls'].clientAddress.enable();
      this.setAddressPassengers(i, this.covenantAddress);
    } else {
      const collaborator = this.collaborators.find(
        (elem) =>
          elem['id'] == this.passengers['controls'][i]['controls'].id.value
      );
      this.passengers['controls'][i]['controls'].clientAddress.setValue(true);
      this.passengers['controls'][i]['controls'].clientAddress.disable();
      this.setAddressPassengers(i, collaborator.address);
      this.passengers['controls'][i]['controls'].address.disable();
    }
  }
  onClickClientAddress(e, i) {
    if (e.target.checked) {
      this.passengers['controls'][i]['controls'].address.disable();
      this.passengers['controls'][i]['controls'].covenantAddress.setValue(
        false
      );
      this.passengers['controls'][i]['controls'].otherAddress.setValue(false);
      this.passengers['controls'][i]['controls'].clientAddress.setValue(true);
      this.passengers['controls'][i]['controls'].clientAddress.disable();
      const collaborator = this.collaborators.find(
        (elem) =>
          elem['id'] == this.passengers['controls'][i]['controls'].id.value
      );
      this.setAddressPassengers(i, collaborator.address);
    }
  }
  onClickOtherAddress(e, i) {
    if (e.target.checked) {
      this.passengers['controls'][i]['controls'].address.enable();
      this.passengers['controls'][i]['controls'].clientAddress.enable();
      this.passengers['controls'][i]['controls'].covenantAddress.setValue(
        false
      );
      this.passengers['controls'][i]['controls'].clientAddress.setValue(false);
      this.cities[i] = [];
      this.resetValueAddressPassenger(i);
    } else {
      const collaborator = this.collaborators.find(
        (elem) =>
          elem['id'] == this.passengers['controls'][i]['controls'].id.value
      );
      this.setAddressPassengers(i, collaborator.address);
      this.passengers['controls'][i]['controls'].clientAddress.setValue(true);
      this.passengers['controls'][i]['controls'].clientAddress.disable();
      this.passengers['controls'][i]['controls'].address.disable();
    }
  }
  setAddressPassengers(i, { id, zipCode, publicPlace, neighborhood, city }) {
    this.passengers['controls'][i]['controls'].address.controls.id.setValue(id);
    this.passengers['controls'][i][
      'controls'
    ].address.controls.zipcode.setValue(zipCode);
    this.passengers['controls'][i][
      'controls'
    ].address.controls.publicPlace.setValue(publicPlace);
    this.passengers['controls'][i][
      'controls'
    ].address.controls.neighborhood.setValue(neighborhood);
    this.passengers['controls'][i]['controls'].address.controls.state.setValue(
      city.state.id
    );
    this.serviceAddress.getCitiesByStateId(city.state.id).subscribe(
      (data) => {
        this.cities[i] = data;
        this.passengers['controls'][i][
          'controls'
        ].address.controls.city.setValue(city.id);

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro ao salvar', 'Erro ao buscar cidades!', 'error');
      }
    );
  }

  onClickCovenantDestinyAddress(e, i) {
    if (e.target.checked) {
      this.passengers['controls'][i]['controls'].destinyAddress.disable();
      this.passengers['controls'][i][
        'controls'
      ].covenantDestinyAddress.setValue(true);
      this.passengers['controls'][i]['controls'].otherDestinyAddress.setValue(
        false
      );
      this.passengers['controls'][i]['controls'].clientDestinyAddress.setValue(
        false
      );
      this.passengers['controls'][i]['controls'].clientDestinyAddress.enable();
      // const collaborators = this.collaborators.filter(elem => elem['id'] == this.passengers['controls'][i]['controls'].id.value);
      this.setDestinyAddressPassengers(i, this.covenantAddress);
    } else {
      const collaborator = this.collaborators.find(
        (elem) =>
          elem['id'] == this.passengers['controls'][i]['controls'].id.value
      );
      this.setDestinyAddressPassengers(i, collaborator.address);
      this.passengers['controls'][i]['controls'].clientDestinyAddress.setValue(
        true
      );
      this.passengers['controls'][i]['controls'].clientDestinyAddress.disable();
      this.passengers['controls'][i]['controls'].destinyAddress.disable();
    }
  }

  onClickClientDestinyAddress(e, i) {
    if (e.target.checked) {
      this.passengers['controls'][i]['controls'].destinyAddress.disable();
      this.passengers['controls'][i][
        'controls'
      ].covenantDestinyAddress.setValue(false);
      this.passengers['controls'][i]['controls'].otherDestinyAddress.setValue(
        false
      );
      this.passengers['controls'][i]['controls'].clientDestinyAddress.setValue(
        true
      );
      this.passengers['controls'][i]['controls'].clientDestinyAddress.disable();
      const collaborator = this.collaborators.find(
        (elem) =>
          elem['id'] == this.passengers['controls'][i]['controls'].id.value
      );
      this.setDestinyAddressPassengers(i, collaborator.address);
    }
  }

  onClickOtherDestinyAddress(e, i) {
    if (e.target.checked) {
      this.passengers['controls'][i]['controls'].destinyAddress.enable();
      this.passengers['controls'][i]['controls'].clientDestinyAddress.enable();
      this.passengers['controls'][i][
        'controls'
      ].covenantDestinyAddress.setValue(false);
      this.passengers['controls'][i]['controls'].clientDestinyAddress.setValue(
        false
      );
      this.selectDestinyCities[i] = [];
      this.resetValueDestinyAddressPassenger(i);
    } else {
      this.passengers['controls'][i]['controls'].clientDestinyAddress.setValue(
        true
      );
      this.passengers['controls'][i]['controls'].clientDestinyAddress.disable();
      const collaborator = this.collaborators.find(
        (elem) =>
          elem['id'] == this.passengers['controls'][i]['controls'].id.value
      );
      this.setDestinyAddressPassengers(i, collaborator.address);
      this.passengers['controls'][i]['controls'].destinyAddress.disable();
    }
  }

  setDestinyAddressPassengers(
    i,
    { id, zipCode, publicPlace, neighborhood, city }
  ) {
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.id.setValue(id);
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.zipcode.setValue(zipCode);
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.publicPlace.setValue(publicPlace);
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.neighborhood.setValue(neighborhood);
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.state.setValue(city.state.id);
    this.serviceAddress.getCitiesByStateId(city.state.id).subscribe(
      (data) => {
        this.selectDestinyCities[i] = data;
        this.passengers['controls'][i][
          'controls'
        ].destinyAddress.controls.city.setValue(city.id);

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro ao salvar', 'Erro ao buscar cidades!', 'error');
      }
    );
  }

  resetValueDestinyAddressPassenger(i) {
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.id.setValue('');
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.zipcode.setValue('');
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.neighborhood.setValue('');
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.publicPlace.setValue('');
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.state.setValue('');
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.city.setValue();
  }

  resetValueAddressPassenger(i) {
    this.passengers['controls'][i][
      'controls'
    ].address.controls.zipcode.setValue('');
    this.passengers['controls'][i][
      'controls'
    ].address.controls.neighborhood.setValue('');
    this.passengers['controls'][i][
      'controls'
    ].address.controls.publicPlace.setValue('');
    this.passengers['controls'][i]['controls'].address.controls.state.setValue(
      ''
    );
    this.passengers['controls'][i]['controls'].address.controls.city.setValue(
      ''
    );
  }

  onCheckboxChange(e) {
    if (e.target.checked) {
      this.runForm.controls.originAddress.get('id').setValue('');
      this.runForm.controls.originAddress.get('zipcode').setValue('');
      this.runForm.controls.originAddress.get('publicPlace').setValue('');
      this.runForm.controls.originAddress.get('state').setValue('');
      this.runForm.controls.originAddress.get('neighborhood').setValue('');
      this.runForm.controls.originAddress.get('city').setValue('');

      this.runForm.controls.originAddress.get('zipcode').enable();
      this.runForm.controls.originAddress.get('publicPlace').enable();
      this.runForm.controls.originAddress.get('city').enable();
      this.runForm.controls.originAddress.get('state').enable();
      this.runForm.controls.originAddress.get('neighborhood').enable();
    } else {
      this.runForm.controls.originAddress.get('zipcode').disable();
      this.runForm.controls.originAddress.get('publicPlace').disable();
      this.runForm.controls.originAddress.get('city').disable();
      this.runForm.controls.originAddress.get('state').disable();
      this.runForm.controls.originAddress.get('neighborhood').disable();

      // reinserindo os dados de cliente após bloquear os dados
      this.runForm.controls.originAddress
        .get('id')
        .setValue(this.clientAddress.id);
      this.runForm.controls.originAddress
        .get('zipcode')
        .setValue(this.clientAddress.zipCode);
      this.runForm.controls.originAddress
        .get('publicPlace')
        .setValue(this.clientAddress.publicPlace);
      this.runForm.controls.originAddress
        .get('state')
        .setValue(this.clientAddress.city.state.id);
      this.runForm.controls.originAddress
        .get('neighborhood')
        .setValue(this.clientAddress.neighborhood);
      this.runForm.controls.originAddress
        .get('city')
        .setValue(this.clientAddress.city.id);
      this.serviceAddress
        .getCitiesByStateId(this.clientAddress.city.state.id)
        .subscribe((data) => {
          this.originCities = data;
        });
    }
  }

  onCheckboxChangeDestinyAddress(e) {
    if (e.target.checked) {
      this.runForm.controls.destinyAddress.get('id').setValue('');
      this.runForm.controls.destinyAddress.get('zipcode').setValue('');
      this.runForm.controls.destinyAddress.get('publicPlace').setValue('');
      this.runForm.controls.destinyAddress.get('state').setValue('');
      this.runForm.controls.destinyAddress.get('neighborhood').setValue('');
      this.runForm.controls.destinyAddress.get('city').setValue('');

      this.runForm.controls.destinyAddress.get('zipcode').enable();
      this.runForm.controls.destinyAddress.get('publicPlace').enable();
      this.runForm.controls.destinyAddress.get('city').enable();
      this.runForm.controls.destinyAddress.get('state').enable();
      this.runForm.controls.destinyAddress.get('neighborhood').enable();
    } else {
      this.runForm.controls.destinyAddress.get('zipcode').disable();
      this.runForm.controls.destinyAddress.get('publicPlace').disable();
      this.runForm.controls.destinyAddress.get('city').disable();
      this.runForm.controls.destinyAddress.get('state').disable();
      this.runForm.controls.destinyAddress.get('neighborhood').disable();

      // reinserindo os dados de cliente após bloquear os dados
      this.runForm.controls.destinyAddress
        .get('id')
        .setValue(this.clientAddress.id);
      this.runForm.controls.destinyAddress
        .get('zipcode')
        .setValue(this.clientAddress.zipCode);
      this.runForm.controls.destinyAddress
        .get('publicPlace')
        .setValue(this.clientAddress.publicPlace);
      this.runForm.controls.destinyAddress
        .get('state')
        .setValue(this.clientAddress.city.state.id);
      this.runForm.controls.destinyAddress
        .get('neighborhood')
        .setValue(this.clientAddress.neighborhood);
      this.runForm.controls.destinyAddress
        .get('city')
        .setValue(this.clientAddress.city.id);
      this.serviceAddress
        .getCitiesByStateId(this.clientAddress.city.state.id)
        .subscribe((data) => {
          this.originCities = data;
        });
    }
  }

  getStates() {
    this.serviceAddress.getStates().subscribe(
      (data) => {
        this.states = data;
      },
      (error) => {
        Swal.fire('Erro', 'Não foi possível Buscar os Estados!', 'error');
      }
    );
  }

  getSearchUser() {
    if (this.runForm.controls.passenger.value == 'client') {
      this.getClientSearch();
    }
    if (this.runForm.controls.passenger.value == 'covenant') {
      this.getCovenantSearch();
    }
  }
  getCovenantSearch() {
    this.loading = true;
    this.serviceCovenant
      .getCovenant(this.run.runRequest.covenantId)
      .subscribe((data) => {
        const value = data;

        if (data) {
          const { address } = value;
          this.covenantAddress = new Address();
          this.covenantAddress.zipCode = address.zipCode;
          this.covenantAddress.neighborhood = address.neighborhood;
          this.covenantAddress.publicPlace = address.publicPlace;
          this.covenantAddress.city = address.city;

          this.covenantId = value.id;
          this.runForm.controls.covenantId.setValue(value.id);
          this.runForm.controls.name.setValue(value.name);
          this.runForm.controls.phone.setValue(value.phone);
          this.runForm.controls.whatsapp.setValue(value.whatsapp);
          this.runForm.controls.originAddress
            .get('id')
            .setValue(value.address.id);
          this.runForm.controls.originAddress
            .get('zipcode')
            .setValue(value.address.zipCode);
          this.runForm.controls.originAddress
            .get('publicPlace')
            .setValue(value.address.publicPlace);
          this.runForm.controls.originAddress
            .get('state')
            .setValue(value.address.city.state.id);
          this.runForm.controls.originAddress
            .get('neighborhood')
            .setValue(value.address.neighborhood);
          this.runForm.controls.originAddress
            .get('city')
            .setValue(value.address.city.id);

          this.serviceAddress
            .getCitiesByStateId(value.address.city.state.id)
            .subscribe((data) => {
              this.originCities = data;
            });

          this.paymentMethods = value.covenantPaymentMethods;

          this.requestValues = [];
          value.covenantRequestValues.forEach((element) => {
            this.requestValues.push(element.requestValue);
          });
          this.collaborators = data['collaborators'] ?? [];
          this.run.passengers.forEach((passenger) => {
            const findCollaborator = this.collaborators.find(
              (e) => e['id'] === passenger.id
            );
            if (!findCollaborator) {
              this.collaborators.push(passenger.collaborator);
            }
            this.runForm.controls.collaborator.setValue(
              passenger.collaborator.id
            );

            const collaborator = { ...passenger, ...passenger.collaborator };

            this.onAddCollaborator(collaborator);
          });

          this.collaborators = this.collaborators.sort((a, b) =>
            a.name.localeCompare(b.name)
          );

          this.loading = false;
          this.selectRequestValue();
        } else {
          this.loading = false;
          this.resetDataForm();
          this.searchClientForm.controls.searchClient.setErrors({
            invalid: 'Convênio não encontrado!',
          });
        }
      });
  }

  getClientSearch() {
    this.serviceClient
      .getClient(this.run.runRequest.clientId)
      .subscribe((data) => {
        const value = data;

        if (data) {
          const { address } = value;
          this.clientAddress = new Address();
          this.clientAddress.zipCode = address.zipCode;
          this.clientAddress.neighborhood = address.neighborhood;
          this.clientAddress.publicPlace = address.publicPlace;
          this.clientAddress.city = address.city;

          this.clientId = value.id;

          this.runForm.controls.clientId.setValue(value.id);

          this.runForm.controls.name.setValue(value.name);
          this.runForm.controls.phone.setValue(value.phone);
          this.runForm.controls.whatsapp.setValue(value.whatsapp);

          if (this.run.passengers[0].origin.zipCode !== address.zipCode) {
            this.runForm.controls.otherAddress.setValue(true);
            this.runForm.controls.originAddress.get('zipcode').enable();
            this.runForm.controls.originAddress.get('publicPlace').enable();
            this.runForm.controls.originAddress.get('city').enable();
            this.runForm.controls.originAddress.get('state').enable();
            this.runForm.controls.originAddress.get('neighborhood').enable();
          } else {
            this.runForm.controls.originAddress.get('zipcode').disable();
            this.runForm.controls.originAddress.get('publicPlace').disable();
            this.runForm.controls.originAddress.get('city').disable();
            this.runForm.controls.originAddress.get('state').disable();
            this.runForm.controls.originAddress.get('neighborhood').disable();
          }

          if (this.run.passengers[0].destiny.zipCode !== address.zipCode) {
            this.runForm.controls.otherDestinyAddress.setValue(true);
            this.runForm.controls.destinyAddress.get('zipcode').enable();
            this.runForm.controls.destinyAddress.get('publicPlace').enable();
            this.runForm.controls.destinyAddress.get('city').enable();
            this.runForm.controls.destinyAddress.get('state').enable();
            this.runForm.controls.destinyAddress.get('neighborhood').enable();
          } else {
            this.runForm.controls.destinyAddress.get('zipcode').disable();
            this.runForm.controls.destinyAddress.get('publicPlace').disable();
            this.runForm.controls.destinyAddress.get('city').disable();
            this.runForm.controls.destinyAddress.get('state').disable();
            this.runForm.controls.destinyAddress.get('neighborhood').disable();
          }

          this.runForm.controls.originAddress
            .get('id')
            .setValue(this.run.passengers[0].origin.id);
          this.runForm.controls.originAddress
            .get('zipcode')
            .setValue(this.run.passengers[0].origin.zipCode);
          this.runForm.controls.originAddress
            .get('publicPlace')
            .setValue(this.run.passengers[0].origin.publicPlace);
          this.runForm.controls.originAddress
            .get('state')
            .setValue(this.run.passengers[0].origin.city.state.id);
          this.runForm.controls.originAddress
            .get('neighborhood')
            .setValue(this.run.passengers[0].origin.neighborhood);
          this.runForm.controls.originAddress
            .get('city')
            .setValue(this.run.passengers[0].origin.city.id);

          this.serviceAddress
            .getCitiesByStateId(this.run.passengers[0].origin.city.state.id)
            .subscribe((data) => {
              this.originCities = data;
            });

          this.runForm.controls.destinyPoint.setValue(
            this.run.passengers[0].destinyPoint.id
          );
          this.runForm.controls.destinyAddress
            .get('id')
            .setValue(this.run.passengers[0].destiny.id);
          this.runForm.controls.destinyAddress
            .get('zipcode')
            .setValue(this.run.passengers[0].destiny.zipCode);
          this.runForm.controls.destinyAddress
            .get('publicPlace')
            .setValue(this.run.passengers[0].destiny.publicPlace);
          this.runForm.controls.destinyAddress
            .get('state')
            .setValue(this.run.passengers[0].destiny.city.state.id);
          this.runForm.controls.destinyAddress
            .get('neighborhood')
            .setValue(this.run.passengers[0].destiny.neighborhood);
          this.runForm.controls.destinyAddress
            .get('city')
            .setValue(this.run.passengers[0].destiny.city.id);

          this.serviceAddress
            .getCitiesByStateId(this.run.passengers[0].destiny.city.state.id)
            .subscribe((data) => {
              this.destinyCities = data;
            });

          this.serviceRequestValue
            .getAllActiveRequestValueClient()
            .subscribe((data) => {
              this.requestValues = data;
              this.selectRequestValue();
            });

          this.paymentMethods = value.paymentMethods;
        } else {
          this.resetDataForm();
          this.searchClientForm.controls.searchClient.setErrors({
            invalid: 'Cliente não encontrado!',
          });
        }
      });
  }
  getPaymentMethods() {
    this.paymentMethodService
      .getPaymentMethods()
      .subscribe((data) => (this.paymentMethods = data));
  }
  disableForms() {
    this.runForm.controls.name.disable();
    this.runForm.controls.whatsapp.disable();
    this.runForm.controls.phone.disable();
    this.runForm.controls.originAddress.get('zipcode').disable();
    this.runForm.controls.originAddress.get('publicPlace').disable();
    this.runForm.controls.originAddress.get('city').disable();
    this.runForm.controls.originAddress.get('state').disable();
    this.runForm.controls.originAddress.get('neighborhood').disable();
  }
  createClientForm() {
    this.searchClientForm = this.fb.group({
      searchClient: ['', [Validators.required]],
    });
  }

  totalSolicitation() {
    const { passenger, destinyPoint } = this.runForm.getRawValue();

    const requestValue = this.requestValues.find(
      (elem) => this.runForm.controls.requestValueId.value == elem.id
    );

    let totalSolicitationValue = 0.0;
    let runPenality = 0.0;

    if (requestValue) {
      switch (passenger) {
        case 'client':
          const dp = requestValue.destinyPoints.find(
            (element) => element.id == destinyPoint
          );

          totalSolicitationValue =
            (requestValue?.value ?? 0) + Number(dp?.value ?? 0);
          break;
        case 'covenant':
          const passengers = this.passengers.getRawValue();
          const destinyPoints = passengers.reduce((acc, item) => {
            const destiny = this.destinyPoints.find(
              (elem) => item.destinyPoint == elem.id
            );
            if (destiny) {
              const index = acc.findIndex((a) => a?.id === destiny.id);

              if (index == -1) {
                acc.push({ id: destiny.id, value: destiny.value });
              }
            }
            return acc;
          }, []);

          const valuePoints = destinyPoints.reduce((acc, item) => {
            acc += Number(item.value);
            return acc;
          }, 0);

          const origins = passengers.reduce((acc, item) => {
            const index = acc.findIndex((a) => a === item.address.zipcode);
            if (index == -1) {
              acc.push(item.address.zipcode);
            }
            return acc;
          }, []);
          totalSolicitationValue =
            (requestValue?.value ?? 0) +
            (valuePoints ?? 0) +
            (origins?.length != 0 ? origins?.length - 1 : 0) *
              (requestValue?.valueOriginPoint ?? 0);
          break;

        default:
          break;
      }

      const { initialHour, endHour, percentagePenalty } = requestValue;

      if (initialHour && endHour && percentagePenalty) {
        // período do valor de corrida para aplicar a multa
        const beginningTime = moment(initialHour, 'hh:mm');
        const endTime = moment(endHour, 'hh:mm');

        // hora de início da corrida
        const beginingRunTime = moment(
          this.runForm.controls.startHour.value,
          'hh:mm'
        );

        // se a hora de início da corrida está dentro do período do valor de corrida para aplicar a multa
        const hasPenality =
          (beginningTime.isBefore(endTime) &&
            beginingRunTime.isSameOrAfter(beginningTime)) ||
          (beginningTime.isAfter(endTime) &&
            (beginingRunTime.isSameOrAfter(beginningTime) ||
              beginingRunTime.isSameOrBefore(endTime)));

        /// console.log({ beginningTime, endTime, beginingRunTime, hasPenality });
        if (hasPenality) {
          const overtimePenalityValue = (
            +totalSolicitationValue * percentagePenalty
          ).toFixed(2);
          runPenality = +overtimePenalityValue;
        }
        this.run.runPenality = runPenality;
      }
    }

    const runOtherValue: number =
      this.runForm.controls.runOtherValue.value ?? 0;
    const runDiscount: number = this.runForm.controls.runDiscount.value ?? 0;

    this.run.runEndValue = (
      +totalSolicitationValue +
      (this.run.runAditionalValue ?? 0) +
      (this.run.runPenality ?? 0) +
      runOtherValue -
      runDiscount
    ).toFixed(2);

    return totalSolicitationValue;
  }

  async onBlurCepOrigin(event) {
    // event.target.value
    this.loading = true;

    const cep = event.target.value.replace(/[^0-9,.]+/g, '');

    if (cep.length === 8) {
      (await this.buscaCep(cep)).subscribe((viacep) => {
        if (!viacep.erro) {
          this.runForm.controls.originAddress.get('id').setValue('');
          this.runForm.controls.originAddress
            .get('neighborhood')
            .setValue(viacep.bairro);
          this.runForm.controls.originAddress
            .get('publicPlace')
            .setValue(viacep.logradouro);

          this.runForm.controls.originAddress.get('city').setValue('');
          this.runForm.controls.originAddress.get('state').setValue('');

          const state = this.states.find(
            (state) => state.initials === viacep.uf.toUpperCase()
          );

          this.runForm.controls.originAddress.get('state').setValue(state.id);

          this.serviceAddress.getCitiesByStateId(state.id).subscribe(
            (data) => {
              this.destinyCities = data;
              const city = this.destinyCities.find(
                (elem) =>
                  elem.name.normalize('NFD') ===
                  viacep.localidade
                    .toUpperCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
              );
              if (city) {
                this.runForm.controls.originAddress
                  .get('city')
                  .setValue(city.id);
              }
              this.loading = false;
            },
            (error) => {
              this.loading = false;
              Swal.fire('Erro ao salvar', 'Erro ao buscar cidades!', 'error');
            }
          );
        } else {
          this.loading = false;
          this.runForm.controls.originAddress
            .get('zipcode')
            .setErrors({ invalid: 'CEP inválido!' });
        }
      });
    } else {
      this.loading = false;
      this.runForm.controls.originAddress
        .get('zipcode')
        .setErrors({ invalid: 'CEP inválido!' });
    }
  }

  async onBlurCepDestiny(event) {
    // event.target.value
    this.loading = true;

    const cep = event.target.value.replace(/[^0-9,.]+/g, '');

    if (cep.length === 8) {
      (await this.buscaCep(cep)).subscribe((viacep) => {
        if (!viacep.erro) {
          this.runForm.controls.destinyAddress.get('id').setValue('');
          this.runForm.controls.destinyAddress
            .get('neighborhood')
            .setValue(viacep.bairro);
          this.runForm.controls.destinyAddress
            .get('publicPlace')
            .setValue(viacep.logradouro);

          this.runForm.controls.destinyAddress.get('city').setValue('');
          this.runForm.controls.destinyAddress.get('state').setValue('');

          const state = this.states.find(
            (state) => state.initials === viacep.uf.toUpperCase()
          );

          this.runForm.controls.destinyAddress.get('state').setValue(state.id);

          this.serviceAddress.getCitiesByStateId(state.id).subscribe(
            (data) => {
              this.destinyCities = data;
              const city = this.destinyCities.find(
                (elem) =>
                  elem.name.normalize('NFD') ===
                  viacep.localidade
                    .toUpperCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
              );
              if (city) {
                this.runForm.controls.destinyAddress
                  .get('city')
                  .setValue(city.id);
              }
              this.loading = false;
            },
            (error) => {
              this.loading = false;
              Swal.fire('Erro ao salvar', 'Erro ao buscar cidades!', 'error');
            }
          );
        } else {
          this.loading = false;
          this.runForm.controls.destinyAddress
            .get('zipcode')
            .setErrors({ invalid: 'CEP inválido!' });
        }
      });
    } else {
      this.loading = false;
      this.runForm.controls.destinyAddress
        .get('zipcode')
        .setErrors({ invalid: 'CEP inválido!' });
    }
  }

  async onBlurCepOriginCollaborator(event, i) {
    this.loading = true;

    const cep = event.target.value.replace(/[^0-9,.]+/g, '');

    if (cep.length === 8) {
      (await this.buscaCep(cep)).subscribe((viacep) => {
        if (!viacep.erro) {
          const { controls } =
            this.passengers['controls'][i]['controls'].address;
          controls.id.setValue(null);
          controls.neighborhood.setValue(viacep.bairro);
          controls.publicPlace.setValue(viacep.logradouro);

          const state = this.states.find(
            (state) => state.initials === viacep.uf.toUpperCase()
          );
          controls.state.setValue(state.id);

          this.serviceAddress.getCitiesByStateId(state.id).subscribe(
            (data) => {
              this.cities[i] = data;
              const city = this.cities[i].find(
                (elem) =>
                  elem.name.normalize('NFD') ===
                  viacep.localidade
                    .toUpperCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
              );
              if (city) {
                controls.city.setValue(city.id);
              }
              this.loading = false;
            },
            (error) => {
              this.loading = false;
              Swal.fire('Erro ao salvar', 'Erro ao buscar cidades!', 'error');
            }
          );

          controls.city.setValue();

          this.loading = false;
        } else {
          this.loading = false;
          this.runForm.controls.passengers
            .get(i)
            .get('zipcode')
            .setErrors({ invalid: 'CEP inválido!' });
        }
      });
    } else {
      this.loading = false;
      this.runForm.controls.passengers
        .get(i)
        .get('zipcode')
        .setErrors({ invalid: 'CEP inválido!' });
    }
  }

  async onBlurCepDestinyCollaborator(event, i) {
    this.loading = true;

    const cep = event.target.value.replace(/[^0-9,.]+/g, '');

    if (cep.length === 8) {
      (await this.buscaCep(cep)).subscribe((viacep) => {
        if (!viacep.erro) {
          const { controls } =
            this.passengers['controls'][i]['controls'].destinyAddress;

          controls.id.setValue(null);
          controls.neighborhood.setValue(viacep.bairro);
          controls.publicPlace.setValue(viacep.logradouro);

          const state = this.states.find(
            (state) => state.initials === viacep.uf.toUpperCase()
          );
          controls.state.setValue(state.id);

          this.serviceAddress.getCitiesByStateId(state.id).subscribe(
            (data) => {
              this.selectDestinyCities[i] = data;
              const city = this.selectDestinyCities[i].find(
                (elem) =>
                  elem.name.normalize('NFD') ===
                  viacep.localidade
                    .toUpperCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
              );
              if (city) {
                controls.city.setValue(city.id);
              }
              this.loading = false;
            },
            (error) => {
              this.loading = false;
              Swal.fire('Erro ao salvar', 'Erro ao buscar cidades!', 'error');
            }
          );

          controls.city.setValue();

          this.loading = false;
        } else {
          this.loading = false;
          this.runForm.controls.passengers
            .get(i)
            .get('zipcode')
            .setErrors({ invalid: 'CEP inválido!' });
        }
      });
    } else {
      this.loading = false;
      this.runForm.controls.passengers
        .get(i)
        .get('zipcode')
        .setErrors({ invalid: 'CEP inválido!' });
    }
  }

  async buscaCep(cep: string) {
    return await this.utilsService.getCep(cep);
  }

  onSelectedStateCollaborator(event, id) {
    this.serviceAddress
      .getCitiesByStateId(event.target.value)
      .subscribe((data) => {
        this.cities[id] = data;
      });
  }
  onSelectedStateOrigin(event) {
    if (event.target.value) {
      this.loading = true;
      this.serviceAddress.getCitiesByStateId(event.target.value).subscribe(
        (data) => {
          this.originCities = data;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          Swal.fire('Ops', 'Erro ao buscar cidades!', 'error');
        }
      );
    }
  }
  onSelectedStateDestiny(event) {
    if (event.target.value) {
      this.loading = true;
      this.serviceAddress.getCitiesByStateId(event.target.value).subscribe(
        (data) => {
          this.destinyCities = data;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          Swal.fire('Ops', 'Erro ao buscar cidades!', 'error');
        }
      );
    }
  }

  getDriversByCar() {
    if (this.runForm.controls.car.value) {
      //this.loading = true;
      this.carService
        .getDriversByCar(this.runForm.controls.car.value)
        .subscribe(
          (data) => {
            this.drivers = data;
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            Swal.fire(
              'Erro',
              'Não foi possível carregar os motoristas deste veículo! Selecione o Veículo novamente.',
              'error'
            );
          }
        );
    }
  }

  getAtualDate() {
    return moment().format('yyyy-MM-DD');
  }

  onSubmit() {
    if (!this.runForm.valid && !this.passengers.valid) return;
    const {
      passenger,
      passengers,
      costCenterId,
      paymentMethod,
      date,
      endDate,
      startHour,
      endHour,
      originAddress,
      destinyAddress,
      destinyPoint,
      car,
      driver,
      observation,
      requestValueId,
      runOtherValue,
      runDiscount,
      covenantCode,
    } = this.runForm.controls;

    this.messageError = '';
    try {
      switch (passenger.value) {
        case 'covenant':
          const passengersSelected = [];
          for (const item of (passengers as FormArray).controls) {
            const {
              id,
              name,
              main,
              address,
              destinyAddress: passengerDestinyAddress,
              destinyPoint,
            } = (item as FormGroup).controls;

            const {
              id: originAddressId,
              zipcode: zipCode,
              publicPlace,
              neighborhood,
              city: cityId,
            } = address.value ?? {};

            const originAddress = {
              originAddressId,
              zipCode,
              publicPlace,
              neighborhood,
              cityId,
            };

            const {
              id: destinyAddressId,
              zipcode: destinyAddressZipCode,
              publicPlace: destinyAddressPublicPlace,
              neighborhood: destinyAddressNeighborhood,
              city: destinyAddressCityId,
            } = passengerDestinyAddress.value ?? {};

            const destinyAddress = {
              destinyAddressId,
              zipCode: destinyAddressZipCode,
              publicPlace: destinyAddressPublicPlace,
              neighborhood: destinyAddressNeighborhood,
              cityId: destinyAddressCityId,
            };

            const originAddressErrors = Object.keys(originAddress).filter(
              (item) => item !== 'originAddressId' && !originAddress[item]
            );

            if (originAddressErrors.length) {
              throw new Error(
                `Selecione o endereço de origem do passageiro ${name.value} ou preencha os campos de Buscar em outro endereço`
              );
            }

            const destinyAddressErrors = Object.keys(destinyAddress).filter(
              (item) => item !== 'destinyAddressId' && !destinyAddress[item]
            );

            if (destinyAddressErrors.length) {
              throw new Error(
                `Selecione o endereço de destino do passageiro ${name.value} ou preencha os campos de Levar em outro endereço`
              );
            }

            if (!destinyPoint.value) {
              throw new Error(
                `Selecione o Quantidade de Pontos do passageiro ${name.value}`
              );
            }

            passengersSelected.push({
              id: id.value,
              isMain: main.value,
              destinyPoint: destinyPoint.value,
              originAddress,
              destinyAddress,
            });
          }

          const isMainErrors = passengersSelected.filter((item) => item.isMain);

          if (!isMainErrors.length) {
            throw new Error(`Selecione pelo menos um passegeiro principal`);
          }

          var value = {};
          value = {
            id: this.runId,
            covenantCode: covenantCode.value,
            costCenter: { id: Number(costCenterId.value) },
            paymentMethod: { id: Number(paymentMethod.value) },
            date: date.value == '' ? null : date.value,
            startHour: startHour.value == '' ? null : startHour.value,
            endHour: endHour.value == '' ? null : endHour.value,
            passengers: passengersSelected,
            driver: driver.value ? { id: Number(driver.value) } : null,
            vehicle: car.value ? { id: Number(car.value) } : null,
            requestValue: { id: Number(requestValueId.value) },
            runValue: this.totalSolicitation(),
            runOtherValue: runOtherValue.value,
            runDiscount: runDiscount.value,
            observation: observation.value,
          };

          this.service.update(this.runId, value).subscribe(
            (data) => {
              Swal.fire(
                'Salvo',
                'Corrida de Convênio editada com Sucesso!',
                'success'
              );
              this.loading = false;
              this.router.navigate(['process/runs']);
            },
            (error) => {
              Swal.fire('Erro', 'Erro ao editar Corrida de Convênio!', 'error');
              this.setErrors(error.error.errors);
              this.loading = false;
            }
          );
          break;

        case 'client':
          this.loading = true;
          var value = {};
          value = {
            id: this.runId,
            covenantCode: covenantCode.value,
            costCenter: { id: Number(costCenterId.value) },
            paymentMethod: { id: Number(paymentMethod.value) },
            date: date.value == '' ? null : date.value,
            startHour: startHour.value == '' ? null : startHour.value,
            endHour: endHour.value == '' ? null : endHour.value,
            vehicle: car.value != '' ? { id: Number(car.value) } : null,
            driver: driver.value != '' ? { id: Number(driver.value) } : null,
            originAddress: {
              originAddressId: originAddress.get('id').value,
              zipCode: originAddress.get('zipcode').value,
              publicPlace: originAddress.get('publicPlace').value,
              neighborhood: originAddress.get('neighborhood').value,
              cityId: Number(originAddress.get('city').value),
            },
            destinyAddress: {
              destinyAddressId: destinyAddress.get('id').value,
              zipCode: destinyAddress.get('zipcode').value,
              publicPlace: destinyAddress.get('publicPlace').value,
              neighborhood: destinyAddress.get('neighborhood').value,
              cityId: Number(destinyAddress.get('city').value),
            },
            requestValue: { id: Number(requestValueId.value) },
            destinyPoint: { id: Number(destinyPoint.value) },
            runValue: this.totalSolicitation(),
            observation: observation.value,
            runOtherValue: runOtherValue.value,
            runDiscount: runDiscount.value,
          };

          this.service.update(this.runId, value).subscribe(
            (data) => {
              Swal.fire(
                'Salvo',
                'Corrida de Cliente editada com Sucesso!',
                'success'
              );
              this.loading = false;
              this.router.navigate(['process/runs']);
            },
            (error) => {
              Swal.fire('Erro', 'Erro ao editar Corrida de Cliente!', 'error');
              this.setErrors(error.error.errors);
              this.loading = false;
            }
          );
          break;

        default:
          Swal.fire('Atenção', 'Tipo de Corrida não identificada!', 'warning');
          break;
      }
    } catch (error) {
      Swal.fire('Atenção', error.message, 'warning');
    }
  }

  setErrors(errors) {
    errors.forEach((erro) => {
      switch (erro.fieldName) {
        default:
          this.messageError += `${erro.message}\n`;
      }
    });
  }

  onCollaboratorSearch($e) {
    if ($e.term) {
      this.serviceCovenant
        .getCollaborators(this.covenantId, $e.term)
        .subscribe((response) => {
          response['results']?.forEach((item) => {
            const findCollaborator = this.collaborators.find(
              (e) => e['id'] === item.id
            );
            if (!findCollaborator) {
              this.collaborators = [...this.collaborators, ...[item]].sort(
                (a, b) => a.name.localeCompare(b.name)
              );
            }
          });
        });
    }
  }
}
