import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { CostCenter } from 'src/app/domain/cost-center';
import { CostCenterService } from 'src/app/service/cost-center/cost-center.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cost-center-create',
  templateUrl: './cost-center-create.component.html',
  styleUrls: ['./cost-center-create.component.css']
})
export class CostCenterCreateComponent implements OnInit {

  costForm: FormGroup;
  loading: boolean = false;
  messageError: string = "";
  costcenters: Array<CostCenter> = [];
  disableSelectCostCenter = "disabled";

  types = [{ name: "Crédito" }, { name: "Débito" }];

  constructor(
    private service: CostCenterService,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.createForm();

    this.service.getAllCostCenters().subscribe(data => {
      this.costcenters = data;
      console.log(data);

    });
  }

  createForm() {
    this.costForm = this.fb.group({
      father: ['', []],
      name: ['', [Validators.required]],
      type: ['', [Validators.required]],
      identifier: ['', [Validators.required]],
      costcenter: ['', []]
    });
  }
  onCheckboxChange(e) {
    if (e.target.checked) {
      this.costForm.controls.identifier.setValue("");
      this.costForm.controls.costcenter.disable();
      this.service.getIdentifier(null).subscribe(data => {
        this.costForm.controls.identifier.setValue(data["nextId"]);
        this.costForm.controls.costcenter.setValue("");
      });
    } else {
      this.costForm.controls.costcenter.enable();
      this.costForm.controls.identifier.setValue("");
    }
  }

  onSelectCostCenter(event) {

    this.service.getIdentifier(this.costForm.controls.costcenter.value).subscribe(data => {
      this.costForm.controls.identifier.setValue(data["nextId"]);
      console.log(data["nextId"]);
    });
  }

  onSubmit() {
    if (this.costForm.controls.costcenter.value) {
      const costcenter = this.costcenters.filter(element => this.costForm.controls.costcenter.value == element.id);
      if (costcenter[0].type != this.costForm.controls.type.value) {
        this.costForm.controls.type.setErrors({ invalid: "Tipo da classe filha deve ser do mesmo tipo da classe pai" });
      }
    }
    if (this.costForm.valid) {
      let costcenter = new CostCenter();
      costcenter.name = this.costForm.controls.name.value;
      costcenter.type = this.costForm.controls.type.value;
      costcenter.identifier = this.costForm.controls.identifier.value;
      if (this.costForm.controls.costcenter.value.length) {
        costcenter.parent = new CostCenter();
        costcenter.parent.id = this.costForm.controls.costcenter.value;
      }
      console.log(costcenter);

      this.loading = true;

      this.service.save(costcenter).subscribe(data => {
        this.loading = false;
        Swal.fire('Salvo', 'Centro de Custo salvo com sucesso!!', 'success').then((result) => {
          if (result.value) {
            this.router.navigate(['financial/cost-center']);
          }
        });
        this.createForm();
      },
        error => {
          console.log(error);
          this.loading = false;
          this.setErrors(error.error.errors);
          Swal.fire('Erro ao salvar', 'Não foi possível salvar Centro de Custo!', 'error');
        });
    }
  }

  resetForm() {
    this.costForm.controls.name.setValue("");
    this.costForm.controls.father.setValue("");
    this.costForm.controls.type.setValue("");

  }

  setErrors(errors) {
    errors.forEach(erro => {
      console.log(erro);

      switch (erro.fieldName) {
        case "name":
          this.costForm.controls.name.setErrors({ invalid: erro.message });
          break;
        case "father":
          this.costForm.controls.father.setErrors({ invalid: erro.message });
          break;
        case "type":
          this.costForm.controls.type.setErrors({ invalid: erro.message });
          break;
        case "identifier":
          this.costForm.controls.identifier.setErrors({ invalid: erro.message });
          break;
        case "costcenter":
          this.costForm.controls.costcenter.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });

  }

}
