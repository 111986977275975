<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Cliente</div>
            </div>
            <div class="col-md-2">
              <a type="button" routerLink="/maintenance/client/new"
                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">Adicionar
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div class="card-head">
              <div class="col-md-4 position-right">
                <select (change)="onSelected($event)" class="custom-select custom-select-sm">
                  <option value="10">Mostrar 10 Resultados</option>
                  <option value="20">Mostrar 20 Resultados</option>
                  <option value="30">Mostrar 30 Resultados</option>
                  <option value="{{ pagination.totalResults }}">
                    Mostrar Todos os Resultados
                  </option>
                </select>
              </div>
              <header>
                <div class="row" [formGroup]="searchForm">
                  <div class="col-12 col-md-6 col-lg mt-2">
                    <input type="text" class="form-control" placeholder="Nome" formControlName="name" />
                  </div>
                  <!-- <div class="col-12 col-md-6 col-lg mt-2">
                    <input
                      type="text"
                      class="form-control"
                      mask="CPF_CNPJ"
                      placeholder="CPF/CNPJ"
                      formControlName="cpfCnpj"
                    />
                  </div> -->
                  <div class="col-12 col-md-6 col-lg mt-2">
                    <input type="text" class="form-control cep" (blur)="onBlurCep($event)" placeholder="CEP"
                      formControlName="zipCode" />
                  </div>
                  <div class="col-12 col-md-6 col-lg mt-2">
                    <select (change)="onSelectedState($event)" class="form-control" formControlName="state">
                      <option value="">Estado</option>
                      <option value="{{ state.id }}" *ngFor="let state of states">
                        {{ state.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-12 col-md-6 col-lg mt-2">
                    <ng-select
                      class="custom-select"
                      style="font-size: 1rem; font-weight: normal"
                      notFoundText="Nenhum item encontrado"
                      placeholder="Cidade"
                      formControlName="city"
                      [items]="cities"
                      bindLabel="name"
                      bindValue="id"
                    >
                    </ng-select>
                  </div>
                  <div class="col-12 col-md-6 col-lg mt-2">
                    <button (click)="onSearch()" class="btn btn-info">
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </header>
            </div>
            <div class="card-body">
              <div class="table-responsive table-mobile">
                <table class="table table-striped custom-table table-hover">
                  <thead>
                    <tr>
                      <!-- <th> Código </th>   -->
                      <th>Nome</th>
                      <!-- <th>CPF</th> -->
                      <th>Telefone</th>
                      <th>WhatsApp</th>
                      <!-- <th>E-mail</th> -->
                      <th>CEP</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let client of clients">
                      <!-- <td>
                        {{ client.id }}
                      </td> -->
                      <td>
                        {{ client.name }}
                      </td>
                      <!-- <td>
                        {{ client.cpfCnpj | mask: "CPF_CNPJ" }}
                      </td> -->
                      <td>
                        {{ client.phone | mask: (client.phone.length == 10 ? "(00) 0000-0000" : "(00) 0 0000-0000") }}
                      </td>
                      <td>
                        {{ client.whatsapp | mask: "+00 00 0 0000-0000" }}
                      </td>
                      <!-- <td>
                        {{ client.email }}
                      </td> -->
                      <td>
                        {{ client.address.zipCode | mask: "00000-000" }}
                      </td>
                      <td>
                        <div class="row">
                          <a routerLink="/maintenance/client/view/{{ client.id }}" class="btn btn-primary btn-xs" alt="Visualizar">
                            <i class="fa fa-eye"></i>
                          </a>
                          <a routerLink="/maintenance/client/edit/{{ client.id }}" class="btn btn-warning btn-xs">
                            <i class="fa fa-pencil"></i>
                          </a>
                          <!-- <a class="btn btn-danger btn-xs">
                                                        <i class="fa fa-trash-o "></i>
                                                    </a> -->

                          <span *ngIf="client.active">
                            <button (click)="onDeActive(client)" class="btn btn-pink btn-xs">
                              <i class="fa fa-random"></i>
                            </button>
                          </span>
                          <span *ngIf="!client.active">
                            <button (click)="onActive(client)" class="btn btn-success btn-xs">
                              <i class="fa fa-random"></i>
                            </button>
                            <button (click)="onResend(client)" class="btn btn-success btn-xs">
                              <i class="fa fa-envelope"></i>
                            </button>
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-md-12">
                    <span *ngIf="!clients.length" class="label label-danger label-mini">Nenhum Cliente
                      encontrado.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" [class.disabled]="!first">
                <button class="page-link" (click)="onFirst()" tabindex="-1">
                  Primeira
                </button>
              </li>
              <li class="page-item" *ngIf="pagination.page != 1">
                <button (click)="onPrevious()" class="page-link">
                  {{ pagination.page - 1 }}
                </button>
              </li>
              <li class="page-item active">
                <button class="page-link">{{ pagination.page }}</button>
              </li>
              <li class="page-item" *ngIf="last">
                <button (click)="onNext()" class="page-link" href="#">
                  {{ pagination.page + 1 }}
                </button>
              </li>
              <li class="page-item" [class.disabled]="!last">
                <button class="page-link" (click)="onLast()">Última</button>
              </li>
            </ul>
          </nav>
        </div>
        <div class="col-md-12">
          Mostrando página {{ pagination.page }} de
          {{ pagination.totalPages }} dos
          {{ pagination.totalResults }} Resultados
        </div>
      </div>
    </div>
  </div>
</div>
