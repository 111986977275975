import { City } from "./city";

export class Address {
    id: number;
    zipCode: string;
    publicPlace: string;
    neighborhood: string;
    city: City = new City;

    constructor() { }
}
