import { Address } from './address';

export class Collaborator {
  id: number;
  name: string;
  email: string;
  role: string;
  phone: string;
  whatsapp: string;
  main: boolean;
  department: string;
  costCenter: string;
  address: Address;
  destinyPoint: string;
  originAddress: Address;
  active: boolean;
  requestAppRace: boolean;
  canResendActivation: boolean;

  constructor() {}
}
