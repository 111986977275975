import { Client } from '../client';
import { AddressDTO } from './address.dto';

export class ClientDTO {
    id: number;
    name: string;
    cpfCnpj: string;
    birthDate: Date;
    email: string;
    phone: string;
    whatsapp: string;
    active: boolean;
    paymentMethods: Array<any>;
    address: AddressDTO;

    constructor(client: Client) {
        this.id = client.id;
        this.name = client.name;
        this.cpfCnpj = client.cpfCnpj;
        this.birthDate = client.birthDate;
        this.email = client.email;
        this.phone = client.phone;
        this.whatsapp = client.whatsapp;
        this.active = client.active;
        this.paymentMethods = client.paymentMethods;
        this.address = new AddressDTO(client.address);

    }
}