<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Contas a Receber Geradas</div>
            </div>
            <div class="col-md-2">
              <a
                type="button"
                routerLink="/financial/generate-bills-to-receive/new"
                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary"
                >Gerar
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div class="card-head">
              <div class="col-md-4 position-right">
                <select class="custom-select custom-select-sm">
                  <option value="10">Mostrar 10 Resultados</option>
                  <option value="20">Mostrar 20 Resultados</option>
                  <option value="30">Mostrar 30 Resultados</option>
                  <option value="">Mostrar Todos os Resultados</option>
                </select>
              </div>
              <header style="width: 100%" [formGroup]="searchForm">
                <div class="row" style="font-size: 14px">
                  <div class="col-sm-3">
                    <label style="font-size: 14px" class="mt-2"
                      >Início (Vencimento)</label
                    >
                    <input
                      type="date"
                      class="form-control"
                      formControlName="date_start"
                    />
                  </div>
                  <div class="col-sm-3">
                    <label style="font-size: 14px" class="mt-2"
                      >Fim (Vencimento)</label
                    >
                    <input
                      type="date"
                      class="form-control"
                      formControlName="date_end"
                    />
                  </div>
                  <div class="col-sm-6">
                    <label class="mt-2">Pesquisar</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Pesquisar Cliente/Convênio - por nome, CPF ou CNPJ"
                      formControlName="client_covenant"
                    />
                  </div>

                  <div class="col-sm-3">
                    <label class="mt-2">Tipo de Recebimento</label>
                    <select
                      class="custom-select custom-select"
                      formControlName="payment_method"
                    >
                      <option value=""></option>
                      <option
                        *ngFor="let item of paymentMethodKeys()"
                        [value]="item"
                      >
                        {{ paymentMethods[item] }}
                      </option>
                    </select>
                  </div>
                  <div class="col-sm-2">
                    <label class="mt-2">Status</label>
                    <select
                      class="custom-select custom-select"
                      formControlName="status"
                    >
                      <option value="false">Aberto</option>
                      <option value="true">Paga</option>
                    </select>
                  </div>
                  <div
                    class="col trip-request__button__search--div float-right"
                  >
                    <a class="btn btn-info mr-3" (click)="onSearch()">
                      <i class="fa fa-search trip-request__button__search"></i>
                    </a>
                    <button
                      (click)="exportBills('pdf')"
                      class="btn btn-danger mr-3"
                      title="Exportar para PDF"
                    >
                      <i
                        class="fa fa-file-pdf-o fa-lg trip-request__button__search"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <button
                      (click)="exportBills('excel')"
                      class="btn btn-success"
                      title="Exportar para Excel"
                    >
                      <i
                        class="fa fa-file-excel-o fa-lg trip-request__button__search"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>
              </header>
            </div>

            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped custom-table table-hover">
                  <thead>
                    <tr>
                      <!-- <th> Código </th>   -->
                      <th>Convênio/Cliente</th>
                      <th>Nota Fiscal</th>
                      <th>Tipo de Recebimento</th>
                      <th>Período</th>
                      <th>Vencimento</th>
                      <th>Valor</th>
                      <th>Valor Líquido</th>
                      <th>Status</th>
                      <th>Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let billGenerated of billsToReceiveGenerated">
                      <!-- <td>{{ billGenerated.id }}</td> -->
                      <td>
                        {{
                          billGenerated.covenant?.name ||
                            billGenerated.client?.name
                        }}
                      </td>
                      <td>{{ billGenerated.invoiceNumber }}</td>
                      <td>
                        {{
                          billGenerated.paymentMethod
                            ? paymentMethod[billGenerated.paymentMethod]
                            : "-"
                        }}
                      </td>
                      <td>
                        {{
                          billGenerated.startDate | date: "dd/MM/yyyy":"GMT"
                        }}
                        -
                        {{ billGenerated.endDate | date: "dd/MM/yyyy":"GMT" }}
                      </td>
                      <td>
                        {{ billGenerated.dueDate | date: "dd/MM/yyyy":"GMT" }}
                      </td>
                      <td>
                        {{
                          this.getTotalValue(billGenerated.runs)
                            | currency: "BRL"
                        }}
                      </td>
                      <td>{{ billGenerated.paidValue | currency: "BRL" }}</td>
                      <td>{{ this.translateStatus(billGenerated.status) }}</td>
                      <td>
                        <div class="">
                          <a
                            routerLink="/financial/generate-bills-to-receive/delete/{{
                              billGenerated.id
                            }}"
                            class="btn btn-danger btn-xs"
                            *ngIf="!billGenerated.paid"
                            title="Excluir conta a receber"
                          >
                            <i class="fa fa-trash-o"></i>
                          </a>
                          <a
                            routerLink="/financial/generate-bills-to-receive/paid/{{
                              billGenerated.id
                            }}"
                            class="btn btn-success btn-xs"
                            *ngIf="!billGenerated.paid"
                            title="Marcar como paga"
                          >
                            <i class="fa fa-check"></i>
                          </a>
                          <button
                            class="btn btn-info btn-xs"
                            (click)="
                              exportGeneratedBillReceiptPdf(
                                billGenerated.id,
                                billGenerated.covenant?.name ||
                                  billGenerated.client?.name
                              )
                            "
                            title="Baixar recibo"
                          >
                            <i class="fa fa-file-pdf-o"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-12">
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                  <li class="page-item" [class.disabled]="!first">
                    <button class="page-link" (click)="onFirst()" tabindex="-1">
                      Primeira
                    </button>
                  </li>
                  <li class="page-item" *ngIf="pagination.page != 1">
                    <button (click)="onPrevious()" class="page-link">
                      {{ pagination.page - 1 }}
                    </button>
                  </li>
                  <li class="page-item active">
                    <button class="page-link">{{ pagination.page }}</button>
                  </li>
                  <li class="page-item" *ngIf="last">
                    <button (click)="onNext()" class="page-link" href="#">
                      {{ pagination.page + 1 }}
                    </button>
                  </li>
                  <li class="page-item" [class.disabled]="!last">
                    <button class="page-link" (click)="onLast()">Última</button>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="col-md-12">
              Mostrando página {{ pagination.page }} de
              {{ pagination.totalPages }} dos
              {{ pagination.totalResults }} Resultados
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
