import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CarService } from 'src/app/service/car/car.service';
import { UtilsService } from 'src/app/service/utils/utils.service';
import { AddressService } from 'src/app/service/address/address.service';
import { State } from 'src/app/domain/state';
import { City } from 'src/app/domain/city';
import Swal from 'sweetalert2';
import { Car } from 'src/app/domain/car';
import { Address } from 'src/app/domain/address';
import { Router } from '@angular/router';
import { Insurer } from 'src/app/domain/insurer';
import { CarModel } from 'src/app/domain/car-model';
import { CarBrand } from 'src/app/domain/car-brand';
import { CarMotor } from 'src/app/domain/car-motor';
import { InsurerService } from 'src/app/service/insurer/insurer.service';
import { CarModelService } from 'src/app/service/car-model/car-model.service';
import { CarBrandService } from 'src/app/service/car-brand/car-brand.service';
import { CarMotorService } from 'src/app/service/car-motor/car-motor.service';
import { Pagination } from 'src/app/domain/pagination';
import { Validations } from '../../validations';

import { LyDialog } from '@alyle/ui/dialog';
import { ImgCropperEvent } from '@alyle/ui/image-cropper';
import { CropperDialog } from '../../cropper/cropper-dialog';

@Component({
  selector: 'app-car-create',
  templateUrl: './car-create.component.html',
  styleUrls: ['./car-create.component.scss'],
})
export class CarCreateComponent implements OnInit {
  step = 1;
  carForm: FormGroup;
  messageError: string = '';
  loading: boolean = false;
  states: Array<State> = [];
  cities: Array<City> = [];

  years: Array<Number> = [];
  insurers: Array<Insurer> = [];
  vehicleModels: Array<CarModel> = [];
  vehicleBrands: Array<CarBrand> = [];
  motors: Array<CarMotor> = [];

  image: File;
  cropped?: string;

  constructor(
    private service: CarService,
    private serviceModel: CarModelService,
    private serviceBrand: CarBrandService,
    private serviceMotor: CarMotorService,
    private serviceInsurer: InsurerService,
    private serviceAddress: AddressService,
    private utilsService: UtilsService,
    private fb: FormBuilder,
    private router: Router,
    private _dialog: LyDialog,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.createForm();

    this.serviceAddress.getStates().subscribe(
      (data) => {
        this.states = data;
      },
      (error) => {
        Swal.fire('Erro', 'Não foi possível Buscar os Estados!', 'error');
      }
    );

    this.getYearList();
    this.getBrands();
    this.getMotors();
    this.getInsurers();
  }

  getYearList() {
    let lastYear = new Date().getFullYear();
    let firstYear = lastYear - 10;
    for (let i = lastYear; i >= firstYear; i--) {
      this.years.push(i);
    }
  }
  onSelected(event) {
    this.getModels(event.target.value);
  }

  getModels(id: number) {
    this.loading = true;
    this.serviceModel.getCarModelsByBrand(id).subscribe(
      (data) => {
        this.vehicleModels = data;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível buscar os modelos!', 'error');
      }
    );
  }

  getBrands() {
    this.serviceBrand.getAllCarBrands().subscribe(
      (data) => {
        this.vehicleBrands = data;
      },
      (error) => {
        Swal.fire('Erro', 'Não foi possível buscar as marcas!', 'error');
      }
    );
  }

  getMotors() {
    this.serviceMotor.getCarMotors().subscribe(
      (data) => {
        this.motors = data['results'];
      },
      (error) => {
        Swal.fire('Erro', 'Não foi possível buscar os motores!', 'error');
      }
    );
  }

  getInsurers() {
    let insurerFilter = new Insurer();
    insurerFilter.active = true;
    this.serviceInsurer.getInsurers(insurerFilter, new Pagination()).subscribe(
      (data) => {
        this.insurers = data['results'];
      },
      (error) => {
        Swal.fire('Erro', 'Não foi possível buscar as seguradoras!', 'error');
      }
    );
  }

  createForm() {
    this.carForm = this.fb.group({
      marca: ['', [Validators.required]],
      modelo: ['', [Validators.required]],
      motor: ['', [Validators.required]],
      cor: ['', [Validators.required, Validators.maxLength(10)]],
      combustivel: ['', [Validators.required]],
      placa: ['', [Validators.required, Validators.maxLength(7)]],
      renavam: ['', [Validators.required, Validators.maxLength(11)]],
      chassi: ['', [Validators.required, Validators.maxLength(17)]],
      ipva: ['', [Validators.required]],
      hodometro: ['', [Validators.required, Validators.maxLength(10)]],
      arCondicionado: ['', []],
      categoria: ['', [Validators.required]],
      type: ['', [Validators.required]],
      ano: ['', [Validators.required]],
      proprietario: ['', [Validators.required]],
      seguradora: ['', [Validators.required]],
      insurancePolicy: ['', [Validators.required, Validators.maxLength(30)]],
      dueDateInsurancePolicy: ['', [Validators.required]],
      qtdPassageiros: ['', [Validators.required, Validators.maxLength(2)]],
      nMalasGrandes: ['', [Validators.maxLength(2)]],
      nMalasPequenas: ['', [Validators.maxLength(2)]],
      estado: [''],
      cidade: [null],
      imageUrl: [''],
    });
  }

  onSelectedState(event) {
    // TODO - Ao selecionar um estado no formulário de busca ir no servidor e buscar as cidades relacionadas ao estado
    if (event.target.value) {
      this.loading = true;
      this.serviceAddress.getCitiesByStateId(event.target.value).subscribe(
        (data) => {
          this.cities = data;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          Swal.fire('Ops', 'Erro ao buscar cidades!', 'error');
        }
      );
    }
  }

  onRemoveImage(event) {
    this.cropped = '';
    this.image = null;
    this.carForm.controls.imageUrl.reset('');
  }

  onSelectImage(file) {
    if (file.size > 10000000) {
      this.image = null;
      Swal.fire('Ops...', 'A imagem não pode ser maior que 10MB!', 'error');
      return false;
    } else {
      this.image = file;
      this.onFileReader();
      return true;
    }
  }

  onFileReader() {
    const reader = new FileReader();
    reader.readAsDataURL(this.image);
    reader.onload = () =>
      this.carForm.controls.imageUrl.setValue(reader.result);
  }

  onSubmit() {
    const values = this.carForm.controls;
    console.log(this.carForm.controls);

    let car = new Car();
    car.vehicleModel = new CarModel();
    car.vehicleModel.vehicleBrand = new CarBrand();
    car.motor = new CarMotor();
    car.insurer = new Insurer();
    car.city = new City();
    car.vehicleModel.vehicleBrand.id = this.carForm.controls.marca.value;
    car.vehicleModel.id = this.carForm.controls.modelo.value;
    car.motor.id = this.carForm.controls.motor.value;
    car.color = this.carForm.controls.cor.value;
    car.year = this.carForm.controls.ano.value;
    car.fuel = this.carForm.controls.combustivel.value.toUpperCase();
    car.carPlate = this.carForm.controls.placa.value;
    car.renavam = this.carForm.controls.renavam.value;
    car.chassi = this.carForm.controls.chassi.value;
    car.expirationDateIpva = this.carForm.controls.ipva.value;
    car.pedometer = this.carForm.controls.hodometro.value;
    car.carAir = this.carForm.controls.arCondicionado.value || false;
    car.category = this.carForm.controls.categoria.value;
    car.type = this.carForm.controls.type.value;
    car.owner = this.carForm.controls.proprietario.value;
    car.insurer.id = this.carForm.controls.seguradora.value;
    car.insurancePolicy = this.carForm.controls.insurancePolicy.value;
    car.dueDateInsurancePolicy =
      this.carForm.controls.dueDateInsurancePolicy.value;
    car.numberPassengers = this.carForm.controls.qtdPassageiros.value;
    car.numberLargeSuitcases = this.carForm.controls.nMalasGrandes.value;
    car.numberSmallSuitcases = this.carForm.controls.nMalasPequenas.value;
    car.city.id = this.carForm.controls.cidade.value;

    this.loading = true;
    this.service.save(car).subscribe(
      (data) => {
        if (this.image) {
          this.service
            .imageUpload(data.body['id'], this.image)
            .subscribe((data) => {
              this.loading = false;
              this.showSuccess();
            }),
            (error) => {
              this.loading = false;
              Swal.fire(
                'Erro ao salvar imagem',
                'Veículo salvo, mas não foi possível salvar a imagem!',
                'error'
              );
            };
        } else {
          this.loading = false;
          this.showSuccess();
        }
      },
      (error) => {
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire(
          'Erro ao salvar',
          'Não foi possível salvar Veículo!',
          'error'
        );
      }
    );
  }

  showSuccess() {
    Swal.fire('Salvo', 'Veículo salvo com sucesso!!', 'success').then(
      (result) => {
        if (result.value) {
          this.router.navigate(['maintenance/car']);
        }
      }
    );
  }

  resetForm() {
    this.carForm.controls.marca.setValue('');
    this.carForm.controls.modelo.setValue('');
    this.carForm.controls.motor.setValue('');
    this.carForm.controls.cor.setValue('');
    this.carForm.controls.ano.setValue('');
    this.carForm.controls.combustivel.setValue('');
    this.carForm.controls.placa.setValue('');
    this.carForm.controls.renavam.setValue('');
    this.carForm.controls.chassi.setValue('');
    this.carForm.controls.ipva.setValue('');
    this.carForm.controls.hodometro.setValue('');
    this.carForm.controls.arCondicionado.setValue('');
    this.carForm.controls.categoria.setValue('');
    this.carForm.controls.seguradora.setValue('');
    this.carForm.controls.proprietario.setValue('');
    this.carForm.controls.qtdPassageiros.setValue('');
    this.carForm.controls.nMalasGrandes.setValue('');
    this.carForm.controls.nMalasPequenas.setValue('');
    this.carForm.controls.cidade.setValue('');
    this.carForm.controls.estado.setValue('');
  }

  setErrors(errors) {
    errors.forEach((erro) => {
      switch (erro.fieldName) {
        case 'marca':
          this.carForm.controls.marca.setErrors({ invalid: erro.message });
          break;
        case 'modelo':
          this.carForm.controls.modelo.setErrors({ invalid: erro.message });
          break;
        case 'motor':
          this.carForm.controls.motor.setErrors({ invalid: erro.message });
          break;
        case 'cor':
          this.carForm.controls.cor.setErrors({ invalid: erro.message });
          break;
        case 'ano':
          this.carForm.controls.ano.setErrors({ invalid: erro.message });
          break;
        case 'combustivel':
          this.carForm.controls.combustivel.setErrors({
            invalid: erro.message,
          });
          break;
        case 'placa':
          this.carForm.controls.placa.setErrors({ invalid: erro.message });
          break;
        case 'renavam':
          this.carForm.controls.renavam.setErrors({ invalid: erro.message });
          break;
        case 'chassi':
          this.carForm.controls.chassi.setErrors({ invalid: erro.message });
          break;
        case 'ipva':
          this.carForm.controls.ipva.setErrors({ invalid: erro.message });
          break;
        case 'hodometro':
          this.carForm.controls.hodometro.setErrors({ invalid: erro.message });
          break;
        case 'arCondicionado':
          this.carForm.controls.arCondicionado.setErrors({
            invalid: erro.message,
          });
          break;
        case 'categoria':
          this.carForm.controls.categoria.setErrors({ invalid: erro.message });
          break;
        case 'proprietario':
          this.carForm.controls.proprietario.setErrors({
            invalid: erro.message,
          });
          break;
        case 'seguradora':
          this.carForm.controls.seguradora.setErrors({ invalid: erro.message });
          break;
        case 'qtdPassageiros':
          this.carForm.controls.qtdPassageiros.setErrors({
            invalid: erro.message,
          });
          break;
        case 'nMalasGrandes':
          this.carForm.controls.nMalasGrandes.setErrors({
            invalid: erro.message,
          });
          break;
        case 'nMalasPequenas':
          this.carForm.controls.nMalasPequenas.setErrors({
            invalid: erro.message,
          });
          break;
        case 'cidade':
          this.carForm.controls.cidade.setErrors({ invalid: erro.message });
          break;
        case 'estado':
          this.carForm.controls.estado.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });
  }

  openCropperDialog(event) {
    const imgFile = event.target.files['0'];
    let selImg = this.onSelectImage(imgFile);

    if (selImg) {
      this.cropped = null!;
      this._dialog
        .open<CropperDialog, Event>(CropperDialog, {
          data: event,
          width: 320,
          disableClose: true,
        })
        .afterClosed.subscribe((result?: ImgCropperEvent) => {
          if (result) {
            this.cropped = result.dataURL;
            this._cd.markForCheck();
          }
        });
    }
  }
}
