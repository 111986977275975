import { Component, OnInit } from '@angular/core';
import { Car } from 'src/app/domain/car';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Pagination } from 'src/app/domain/pagination';
import { CarFine } from 'src/app/domain/car-fine';
import { CarFineService } from 'src/app/service/car-fine/car-fine.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Driver } from 'src/app/domain/driver';
import { DriverService } from 'src/app/service/driver/driver.service';
import { CarService } from 'src/app/service/car/car.service';
import { Validations } from '../../validations';

@Component({
  selector: 'app-car-fine',
  templateUrl: './car-fine.component.html',
  styleUrls: ['./car-fine.component.css'],
})
export class CarFineComponent implements OnInit {
  car: Car = new Car();
  carFineForm: FormGroup;
  searchCarFineForm: FormGroup;
  loading: boolean = false;
  messageError: string = '';
  pagination: Pagination = new Pagination();
  carId: number;
  fines: Array<CarFine> = [];
  drivers: Array<Driver> = [];

  first: boolean = false;
  last: boolean = false;

  constructor(
    private service: CarFineService,
    private serviceDriver: DriverService,
    private serviceCar: CarService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      this.carId = params['id'];
    });

    this.route.queryParams.subscribe((params) => {
      this.pagination.per_page = params.per_page || 10;
      this.pagination.page = params.page || 1;
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.createFormSearch();
    this.getFines();
    this.getCar();
  }

  // ---------------- FUNÇÕES DE LISTAGEM DE MANUTENÇÕES --------------------
  getFines() {
    this.loading = true;
    this.service
      .getCarFines(
        this.carId,
        this.searchCarFineForm.getRawValue(),
        this.pagination
      )
      .subscribe(
        (data) => {
          this.fines = data['results'];
          this.loading = false;
          this.setPagination(data);
          console.log(this.fines);
        },
        (error) => {
          this.loading = false;
          this.openAlertError();
        }
      );
  }

  getCar() {
    this.serviceCar.getCar(this.carId).subscribe(
      (data) => {
        this.car = data;
        this.getDrivers();
      },
      (error) => {
        Swal.fire(
          'Erro ao carregar dados',
          'Erro ao buscar dados do veículo!',
          'error'
        );
      }
    );
  }
  createFormSearch() {
    this.searchCarFineForm = this.fb.group({
      start_date: ['', []],
      end_date: ['', []],
      providerId: ['', []],
      discount_driver: ['', []],
      hasDriverPaymentId: ['', []],
    });
  }

  getDrivers() {
    this.loading = true;
    this.serviceCar.getDriversByCar(this.carId).subscribe(
      (data) => {
        this.drivers = data;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire(
          'Erro ao carregar dados',
          'Erro ao buscar dados de Motoristas!',
          'error'
        );
      }
    );
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? (this.first = false) : (this.first = true);
    data['totalPages'] <= this.pagination.page
      ? (this.last = false)
      : (this.last = true);
  }

  onFirst() {
    this.pagination.page = 1;
    this.getFines();
  }
  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.getFines();
  }
  onPrevious() {
    this.pagination.page--;
    this.getFines();
  }
  onNext() {
    this.pagination.page++;
    this.getFines();
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar as multas!', 'error');
  }
  // ---------------- FUNÇÕES DE LISTAGEM DE MANUTENÇÕES --------------------

  // ---------------- FUNÇÕES DE CADASTRO e EDIÇÃO DE MANUTENÇÕES --------------------
  createForm() {
    this.carFineForm = this.fb.group({
      id: [null],
      data: ['', [Validators.required, Validations.validDateMoreThanToday]],
      tipo: ['', [Validators.required, Validators.maxLength(20)]],
      motorista: ['', [Validators.required]],
      valor: [
        '',
        [
          Validators.required,
          Validators.maxLength(10),
          Validations.validValueMoreThanZero,
        ],
      ],
      descontarValor: ['', [Validators.required]],
      descontado: ['', [Validators.required]],
    });
  }

  onSubmit() {
    if (!this.loading) {
      const values = this.carFineForm.controls;
      let carFine = new CarFine();
      carFine.id = +values.id.value;
      carFine.driver = new Driver();
      carFine.date = values.data.value;
      carFine.type = values.tipo.value;
      carFine.value = values.valor.value;
      carFine.driver.id = values.motorista.value;
      carFine.discountDriver = values.descontarValor.value;
      carFine.discounted = values.descontado.value;

      this.loading = true;
      if (!carFine.id) {
        this.service.save(this.carId, carFine).subscribe(
          (data) => {
            this.loading = false;
            Swal.fire('Salvo', 'Multa salva com sucesso!!', 'success');
            this.createForm();
            this.getFines();
          },
          (error) => {
            console.log(error);
            this.loading = false;
            this.setErrors(error.error.errors);
            Swal.fire(
              'Erro ao salvar',
              'Não foi possível salvar Multa!',
              'error'
            );
          }
        );
      } else {
        this.service.update(this.carId, carFine).subscribe(
          (data) => {
            this.loading = false;
            Swal.fire('Salvo', 'Multa salva com sucesso!!', 'success');
            this.createForm();
            this.getFines();
          },
          (error) => {
            console.log(error);
            this.loading = false;
            this.setErrors(error.error.errors);
            Swal.fire(
              'Erro ao salvar',
              'Não foi possível salvar Multa!',
              'error'
            );
          }
        );
      }
    }
  }

  editFine(fine: CarFine) {
    console.log(fine);
    this.carFineForm.patchValue({
      id: fine.id,
      data: fine.date,
      motorista: fine.driver.id,
      tipo: fine.type,
      valor: fine.value,
      descontarValor: fine.discountDriver,
      descontado: fine.discounted,
    });
  }

  onFineDelete(carFine) {
    this.service.delete(this.carId, carFine).subscribe(
      (data) => {
        this.loading = false;
        Swal.fire('Excluido!', 'Multa excluída com sucesso!!', 'success');
        this.createForm();
        this.getFines();
      },
      (error) => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire(
          'Erro ao excluir',
          'Não foi possível excluir Multa!',
          'error'
        );
      }
    );
  }

  resetForm() {
    this.carFineForm.controls.id.setValue('');
    this.carFineForm.controls.data.setValue('');
    this.carFineForm.controls.tipo.setValue('');
    this.carFineForm.controls.valor.setValue('');
    this.carFineForm.controls.motorista.setValue('');
    this.carFineForm.controls.descontarValor.setValue('');
    this.carFineForm.controls.descontado.setValue('');
  }

  setErrors(errors) {
    errors.forEach((erro) => {
      switch (erro.fieldName) {
        case 'data':
          this.carFineForm.controls.data.setErrors({ invalid: erro.message });
          break;
        case 'tipo':
          this.carFineForm.controls.tipo.setErrors({ invalid: erro.message });
          break;
        case 'valor':
          this.carFineForm.controls.valor.setErrors({ invalid: erro.message });
          break;
        case 'motorista':
          this.carFineForm.controls.motorista.setErrors({
            invalid: erro.message,
          });
          break;
        case 'descontarValor':
          this.carFineForm.controls.descontarValor.setErrors({
            invalid: erro.message,
          });
        case 'descontado':
          this.carFineForm.controls.descontado.setErrors({
            invalid: erro.message,
          });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });
  }
}
