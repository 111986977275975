import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { AlertService } from 'src/app/service/alert/alert.service';
import { Alert } from 'src/app/domain/alert';
import { AlertType } from 'src/app/domain/alert-type';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Validations } from '../../validations';

@Component({
  selector: 'app-alert-create',
  templateUrl: './alert-create.component.html',
  styleUrls: ['./alert-create.component.css'],
})
export class AlertCreateComponent implements OnInit {
  alertForm: FormGroup;
  loading: boolean = false;
  messageError: string = '';
  alertTypes: Array<AlertType> = [];

  constructor(
    private service: AlertService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.service.getAlertTypes().subscribe(
      (data) => {
        this.alertTypes = data;
      },
      (error) => {
        Swal.fire('Erro ao salvar', 'Erro ao buscar Tipos de Alerta!', 'error');
      }
    );
  }

  createForm() {
    this.alertForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      variable: new FormControl('', [
        Validators.required,
        Validations.validNegativo,
      ]),
      alertType: new FormControl('', [Validators.required]),
      alertOrigin: new FormControl('', [Validators.required]),
      originNotificationId:  new FormControl('', [Validators.required]),
    });
  }

  clearOption() {
    this.alertForm.get('originNotificationId').setValue("");
  }

  onSubmit() {
    if (this.alertForm.valid) {
      const values = this.alertForm.controls;

      let alert = new Alert();
      alert.alertType = new AlertType();

      alert.name = this.alertForm.controls.name.value;
      alert.variable = this.alertForm.controls.variable.value;
      alert.alertType.id = Number(this.alertForm.controls.alertType.value);
      alert.originNotificationId = this.alertForm.controls.originNotificationId.value

      this.loading = true;
      this.service.save(alert).subscribe(
        (data) => {
          this.loading = false;
          Swal.fire('Salvo', 'Alerta salvo com sucesso!!', 'success').then(
            (result) => {
              if (result.value) {
                this.router.navigate(['parameters/alert']);
              }
            }
          );
          this.createForm();
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.setErrors(error.error.errors);
          Swal.fire('Erro ao salvar', 'Não foi possível salvar Alerta!', 'error');
        }
      );
    }
  }

  resetForm() {}

  setErrors(errors) {
    errors.forEach((erro) => {
      console.log(erro);

      switch (erro.fieldName) {
        case 'name':
          this.alertForm.controls.name.setErrors({ invalid: erro.message });
          break;
        case 'variable':
          this.alertForm.controls.variable.setErrors({ invalid: erro.message });
          break;
        case 'alertType':
          this.alertForm.controls.alertType.setErrors({
            invalid: erro.message,
          });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });
  }
}
