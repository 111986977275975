import { Component, OnInit } from '@angular/core';
import { Client } from 'src/app/domain/client';
import { Pagination } from 'src/app/domain/pagination';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ClientService } from 'src/app/service/client/client.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ViaCep } from 'src/app/domain/viacep';
import { State } from 'src/app/domain/state';
import { City } from 'src/app/domain/city';
import { AddressService } from 'src/app/service/address/address.service';
import { UtilsService } from 'src/app/service/utils/utils.service';
import { Address } from 'src/app/domain/address';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css'],
})
export class ClientComponent implements OnInit {
  clients: Array<Client> = [];
  pagination: Pagination = new Pagination();
  loading: boolean = true;
  client: Client = new Client();
  searchForm: FormGroup;
  showResultsForm: FormGroup;
  viacep: ViaCep = new ViaCep();
  states: Array<State> = [];
  cities: Array<City> = [];

  first: boolean = false;
  last: boolean = false;

  constructor(
    private service: ClientService,
    private route: ActivatedRoute,
    private serviceAddress: AddressService,
    private utilsService: UtilsService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.route.queryParams.subscribe((params) => {
      this.client.address = new Address();
      this.client.address.city = new City();
      this.client.address.city.state = new State();

      this.pagination.per_page = params.per_page || 10;
      this.pagination.page = params.page || 1;
      this.client.name = params.name || '';
      this.client.address.zipCode = params.zipCode || '';
      this.client.address.city.state.id = params.state || '';
      this.client.address.city.id = params.city || null;

      console.log(this.client.address.city.id);
    });
  }

  ngOnInit(): void {
    this.serviceAddress.getStates().subscribe(
      (data) => {
        this.states = data;
      },
      (error) => {
        Swal.fire('Erro', 'Não foi possível Buscar os Estados!', 'error');
      }
    );

    this.createForm();
    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;
    this.getClients();
  }

  onResend(client) {
    this.service.resendEmailSender(client.id).subscribe(
      (data) => {
        Swal.fire(
          'Sucesso',
          'Email de Ativação enviado com Sucesso!!',
          'success'
        );
      },
      (error) => {
        Swal.fire('Falha', 'Erro ao enviar Email de Ativação!!', 'error');
      }
    );
  }

  getClients() {
    this.loading = true;

    this.service.getClients(this.client, this.pagination).subscribe(
      (data) => {
        this.clients = data['results'];
        this.setPagination(data);

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.openAlertError();
      }
    );
  }

  onBlurCep(event) {
    // event.target.value
    this.loading = true;

    if (event.target.value.replace(/[^0-9,.]+/g, '').length === 8) {
      this.utilsService
        .getCep(event.target.value.replace(/[^0-9,.]+/g, ''))
        .subscribe(
          (data) => {
            this.viacep = data;

            if (!this.viacep.erro) {
              const state = this.states.find(
                (state) => state.initials === this.viacep.uf.toUpperCase()
              );

              this.searchForm.controls.state.setValue(state.id);

              // TODO - Implementar busca de cidades por UF
              this.serviceAddress.getCitiesByStateId(state.id).subscribe(
                (data) => {
                  this.cities = data;
                  const city = this.cities.find(
                    (elem) =>
                      elem.name.normalize('NFD') ===
                      this.viacep.localidade
                        .toUpperCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                  );
                  if (city) {
                    this.searchForm.controls.city.setValue(city.id);
                  }
                },
                (error) => {
                  Swal.fire(
                    'Erro ao salvar',
                    'Erro ao buscar cidades!',
                    'error'
                  );
                }
              );
            } else {
              this.searchForm.controls.zipCode.setErrors({
                invalid: 'CEP não encontrado!',
              });
            }
            this.loading = false;
          },
          (erro) => {
            this.searchForm.controls.zipCode.setErrors({
              invalid: 'CEP não encontrado!',
            });
            this.loading = false;
          }
        );
    } else if (event.target.value.replace(/[^0-9,.]+/g, '').length === 0) {
      this.resetForm();
      this.getClients();
    } else {
      this.loading = false;
      this.searchForm.controls.zipCode.setErrors({ invalid: 'CEP inválido!' });
    }
  }

  onSelectedState(event) {
    if (event.target.value) {
      this.loading = true;
      this.serviceAddress.getCitiesByStateId(event.target.value).subscribe(
        (data) => {
          this.cities = data;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          Swal.fire('Ops', 'Erro ao buscar cidades!', 'error');
        }
      );
    }
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? (this.first = false) : (this.first = true);
    data['totalPages'] <= this.pagination.page
      ? (this.last = false)
      : (this.last = true);
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
  }

  createForm() {
    this.showResultsForm = this.fb.group({
      per_page: [this.pagination.per_page],
    });
    this.searchForm = this.fb.group({
      name: [this.client.name],
      cpfCnpj: [this.client.cpfCnpj],
      zipCode: [this.client.address.zipCode],
      city: [this.client.address.city.id],
      state: [this.client.address.city.state.id],
      active: [this.client.active],
    });
  }

  onSearch() {
    const values = this.searchForm.getRawValue();

    this.client.name = this.searchForm.controls.name.value;
    this.client.address.zipCode = this.searchForm.controls.zipCode.value;
    this.client.address.city.state.id = values.state;
    this.client.address.city.id = values.city;

    this.setNavigate();
  }

  onSelected(event) {
    this.pagination.per_page = event.target.value;
    this.setNavigate();
  }

  onActive(client: Client) {
    Swal.fire({
      title: 'Atenção!',
      text: `Ativar Cliente? ${client.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero ativar!',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.active(client.id).subscribe(
          (data) => {
            Swal.fire({
              title: 'Ativado',
              text: `Cliente ativado com sucesso!`,
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
            }).then((result) => {
              location.reload();
            });
          },
          (error) => {
            Swal.fire('Ops', 'Erro ao ativar Cliente!', 'error');
          }
        );
      }
    });
  }

  onDeActive(client: Client) {
    Swal.fire({
      title: 'Atenção!',
      text: `Desativar Cliente? ${client.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero desativar!',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.deactive(client.id).subscribe(
          (data) => {
            Swal.fire({
              title: 'Desativado',
              text: `Cliente desativado com sucesso!`,
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
            }).then((result) => {
              location.reload();
            });
          },
          (error) => {
            Swal.fire('Ops', 'Erro ao desativar Cliente!', 'error');
          }
        );
      }
    });
  }

  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }

  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }

  onPrevious() {
    this.pagination.page--;
    this.setNavigate();
  }

  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  resetForm() {
    this.searchForm.controls.zipCode.setValue('');
    this.searchForm.controls.city.setValue('');
    this.searchForm.controls.state.setValue('');
  }

  setNavigate() {
    this.router.navigate(['/maintenance/client'], {
      queryParams: {
        name: this.client.name ?? '',
        cpfCnpj: this.client.cpfCnpj ?? '',
        zipCode: `${this.client.address.zipCode}`.replace(/\D/g, '') ?? '',
        state: this.client.address.city.state.id ?? '',
        city: this.client.address.city.id ?? '',
        page: this.pagination.page ?? 1,
        per_page: this.pagination.per_page ?? 10,
      },
    });

    this.getClients();
  }
}
