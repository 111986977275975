<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Convênio</div>
            </div>
            <div class="col-md-2">
              <a
                type="button"
                routerLink="/maintenance/covenant/new"
                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary"
                >Adicionar
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <ngx-loading [show]="loading"> </ngx-loading>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div class="card-head">
              <div class="col-md-4 position-right">
                <select
                  (change)="onSelected($event)"
                  formControlName="per_page"
                  class="custom-select custom-select-sm"
                >
                  <option value="10">Mostrar 10 Resultados</option>
                  <option value="20">Mostrar 20 Resultados</option>
                  <option value="30">Mostrar 30 Resultados</option>
                  <option value="{{ pagination.totalResults }}">
                    Mostrar Todos os Resultados
                  </option>
                </select>
              </div>
              <header style="width: 100%">
                <div [formGroup]="searchForm">
                  <div
                    class="row col-12 mt-0 p-0 mb-3"
                    [formGroup]="searchForm"
                  >
                    <!-- <div class="col">Pesquisar</div> -->
                    <div class="col-12 col-md-6 mt-2 col-lg-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Nome"
                        formControlName="name"
                      />
                    </div>
                    <div class="col-12 col-md-6 mt-2 col-lg-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="CPF/CNPJ"
                        mask="CPF_CNPJ"
                        formControlName="cpfCnpj"
                      />
                    </div>
                    <div class="col-12 col-md-6 mt-2 col-lg-3">
                      <select
                        (change)="onSelectedState($event)"
                        class="form-control"
                        formControlName="state"
                      >
                        <option value="">Estado</option>
                        <option
                          value="{{ state.id }}"
                          *ngFor="let state of states"
                        >
                          {{ state.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-12 col-md-6 mt-2 col-lg-3">
                      <ng-select
                        class="custom-select"
                        style="font-size: 1rem; font-weight: normal"
                        notFoundText="Nenhum item encontrado"
                        placeholder="Cidade"
                        formControlName="city"
                        [items]="cities"
                        bindLabel="name"
                        bindValue="id"
                      >
                      </ng-select>
                    </div>
                    <div class="col-12 col-md-6 mt-2 col-lg-3">
                      <select formControlName="active" class="form-control">
                        <option value="null">Status</option>
                        <option value="true">Ativo</option>
                        <option value="false">Inativo</option>
                      </select>
                    </div>
                    <div class="col-12 col-md-6 mt-2 col-lg-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Nome/Email/Whatsapp do Colaborador"
                        formControlName="collaborator"
                      />
                    </div>
                    <div class="row col-12 col-lg-3 m-0 mt-2">
                      <!-- <span class="input-group-btn search-btn"> -->
                      <a (click)="onSearch(1)" class="btn btn-info">
                        <i class="fa fa-search"></i>
                      </a>
                      <!-- </span> -->
                    </div>
                  </div>
                </div>
              </header>
            </div>
            <div class="card-body">
              <div class="table-responsive table-mobile">
                <table class="table table-striped custom-table table-hover">
                  <thead>
                    <tr>
                      <!-- <th> Código </th>   -->
                      <th>Nome</th>
                      <!-- <th>CPF/CNPJ</th> -->
                      <th>Telefone</th>
                      <th>WhatsApp</th>
                      <th>Cidade/Estado</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let covenant of covenants">
                      <!-- <td>{{ covenant.id }}</td> -->

                      <td class="w-100">
                        {{ covenant.name }}
                        <i
                          *ngIf="collaborator"
                          class="fa fa-users text-info ml-3"
                          [title]="getCollaboratorsName(covenant.collaborators)"
                        ></i>
                      </td>
                      <!-- <td>
                        {{ covenant.cpfCnpj | mask: "CPF_CNPJ" }}
                      </td> -->
                      <td class="w-100">
                        {{
                          covenant.phone
                            | mask
                              : (covenant.phone.length == 10
                                  ? "(00) 0000-0000"
                                  : "(00) 0 0000-0000")
                        }}
                      </td>
                      <td class="w-100">
                        {{ covenant.whatsapp | mask : "+00 00 0 0000-0000" }}
                      </td>
                      <td class="w-100">
                        {{ covenant.address.city.name }} ({{
                          covenant.address.city.state.initials
                        }})
                      </td>
                      <td class="w-100">
                        <span
                          *ngIf="covenant.active"
                          class="label label-sucess label-mini"
                          >Ativo</span
                        >
                        <span
                          *ngIf="!covenant.active"
                          class="label label-danger label-mini"
                          >Inativo</span
                        >
                      </td>
                      <td class="w-100">
                        <a
                          routerLink="/maintenance/covenant/view/{{
                            covenant.id
                          }}"
                          class="btn btn-primary btn-xs"
                        >
                          <i class="fa fa-eye"></i>
                        </a>
                        <a
                          routerLink="/maintenance/covenant/edit/{{
                            covenant.id
                          }}"
                          class="btn btn-warning btn-xs"
                        >
                          <i class="fa fa-pencil"></i>
                        </a>

                        <!-- <button (click)="onDelete(covenant)" class="btn btn-danger btn-xs">
                                                    <i class="fa fa-trash-o "></i>
                                                </button> -->
                        <span *ngIf="covenant.active">
                          <button
                            (click)="onDeActive(covenant)"
                            class="btn btn-pink btn-xs"
                          >
                            <i class="fa fa-random"></i>
                          </button>
                        </span>
                        <span *ngIf="!covenant.active">
                          <button
                            (click)="onActive(covenant)"
                            class="btn btn-success btn-xs"
                          >
                            <i class="fa fa-random"></i>
                          </button>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-md-12">
                    <span
                      *ngIf="!covenants.length"
                      class="label label-danger label-mini"
                      >Nenhum Convênio encontrado.</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" [class.disabled]="!first">
                <button class="page-link" (click)="onFirst()" tabindex="-1">
                  Primeira
                </button>
              </li>
              <li class="page-item" *ngIf="pagination.page != 1">
                <button (click)="onPrevious()" class="page-link">
                  {{ pagination.page - 1 }}
                </button>
              </li>
              <li class="page-item active">
                <button class="page-link">{{ pagination.page }}</button>
              </li>
              <li class="page-item" *ngIf="last">
                <button (click)="onNext()" class="page-link" href="#">
                  {{ pagination.page + 1 }}
                </button>
              </li>
              <li class="page-item" [class.disabled]="!last">
                <button class="page-link" (click)="onLast()">Última</button>
              </li>
            </ul>
          </nav>
        </div>
        <div class="col-md-12">
          Mostrando página {{ pagination.page }} de
          {{ pagination.totalPages }} dos
          {{ pagination.totalResults }} Resultados
        </div>
      </div>
    </div>
  </div>
</div>
