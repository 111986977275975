import { Component, OnInit } from '@angular/core';
import { Alert } from 'src/app/domain/alert';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/service/alert/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AlertType } from 'src/app/domain/alert-type';
import { Validations } from '../../validations';

@Component({
  selector: 'app-alert-update',
  templateUrl: './alert-update.component.html',
  styleUrls: ['./alert-update.component.css']
})
export class AlertUpdateComponent implements OnInit {

  alertForm: FormGroup;
  loading: boolean = false;
  messageError: string = "";
  alert: Alert = new Alert();
  alertTypes: Array<AlertType> = [];

  constructor(
    private service: AlertService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe(params => {
      this.alert.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();

    this.service.getAlertTypes().subscribe(data => {
      this.alertTypes = data;
    }, error => {
      Swal.fire('Erro ao salvar', 'Erro ao buscar Tipos de Alerta!', 'error');
    });

    this.service.getAlert(this.alert.id).subscribe(data => {
      this.alert = data;
      this.setValues();
    }, error => {
      this.loading = false;
      Swal.fire('Erro', 'Não foi possível Buscar Alerta!', 'error');
    });

  }
  createForm() {
    this.alertForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      variable: ['', [Validators.required, Validations.validNegativo]],
      alertType: ['', [Validators.required]],
      active: ['', Validators.required]
    });
  }
  setValues() {
    this.alertForm.controls.name.setValue(this.alert.name);
    this.alertForm.controls.variable.setValue(this.alert.variable);
    this.alertForm.controls.alertType.setValue(this.alert.alertType.id);
    this.alertForm.controls.active.setValue(`${this.alert.active}`);
  }

  onSubmit() {
    this.alert.name = this.alertForm.controls.name.value;
    this.alert.variable = this.alertForm.controls.variable.value;
    this.alert.alertType.id = this.alertForm.controls.alertType.value;
    if (this.alertForm.controls.active.value == "true") {
      this.alert.active = true
    } else {
      this.alert.active = false;
    }
    this.loading = true;
    this.service.update(this.alert).subscribe(data => {
      this.loading = false;
      Swal.fire('Editado', 'Alerta editado com sucesso!!', 'success').then((result) => {
        if (result.value) {
          this.router.navigate(['parameters/alert']);
        }
      });
    },
      error => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire('Erro ao editar', 'Não foi possível editar Alerta!', 'error');
      });
  }

  setErrors(errors) {
    errors.forEach(erro => {
      switch (erro.fieldName) {
        case "name":
          this.alertForm.controls.name.setErrors({ invalid: erro.message });
          break;
        case "variable":
          this.alertForm.controls.variable.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;

      }
    });

  }


}
