import { Time } from '@angular/common';
import { EnumRequestValueType } from '../enumerations/request-value-type';
import { DestinyPoint } from './destiny-point';

export class RequestValue {
  id: number
  type: EnumRequestValueType | string; // Cliente ou Convênio
  destiny: string;
  value: number;
  initialHour: Time;
  endHour: Time;
  aditionalValue: number; // Valor adicional de corrida
  tollAmount?: number; // Valor Pedágio
  valueOriginPoint: number
  percentage: number; // Comissão do Motorista
  percentagePenalty: number;
  destinyPoints: Array<DestinyPoint> = [];
  active: boolean;
}
