import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Covenant } from 'src/app/domain/covenant';
import { Pagination } from 'src/app/domain/pagination';
import { CovenantDTO } from 'src/app/domain/dto/covenant.dto';
import { Collaborator } from 'src/app/domain/collaborator';

@Injectable({
  providedIn: 'root',
})
export class CovenantService {
  url: string = `${environment.BASE_URL}/covenants`;
  paymentMethodsUrl: string = `${environment.BASE_URL}/payment-methods`;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  });

  constructor(private http: HttpClient) {}

  getCovenants(
    covenant: Covenant,
    pagination: Pagination,
    collaborator: string
  ) {
    const params = {
      page: `${pagination.page ?? 1}`,
      per_page: `${pagination.per_page ?? 10}`,
      name: `${covenant.name ?? ''}`,
      cpfCnpj: `${covenant.cpfCnpj ?? ''}`,
      state: `${covenant.address.city.state.id ?? ''}`,
      city: `${covenant.address.city.id ?? ''}`,
      active: `${covenant.active ?? ''}`,
      collaborator: collaborator ?? '',
    };

    return this.http.get(this.url, { responseType: 'json', params: params });
  }

  getCovenantsBackoffice(
    covenant: Covenant,
    pagination: Pagination,
    collaborator: string
  ) {
    const params = {
      page: `${pagination.page ?? 1}`,
      per_page: `${pagination.per_page ?? 10}`,
      name: `${covenant.name ?? ''}`,
      cpfCnpj: `${covenant.cpfCnpj ?? ''}`,
      state: `${covenant.address.city.state.id ?? ''}`,
      city: `${covenant.address.city.id ?? ''}`,
      active: `${covenant.active ?? ''}`,
      collaborator: collaborator ?? '',
    };

    return this.http.get(`${this.url}/list-covenants-backoffice`, {
      responseType: 'json',
      params: params,
    });
  }

  getPaymentMethods() {
    return this.http.get(this.paymentMethodsUrl, { responseType: 'json' });
  }

  getCovenant(id: number) {
    return this.http.get<Covenant>(`${this.url}/${id}`, {
      responseType: 'json',
    });
  }

  getCovenantBasic(id: number) {
    return this.http.get<Covenant>(`${this.url}/${id}/basic`, {
      responseType: 'json',
    });
  }

  getCovenantPortal(id: number) {
    return this.http.get<Covenant>(`${this.url}/portal/${id}`, {
      responseType: 'json',
    });
  }
  getCollaborators(id: number, term) {
    return this.http.get<Array<Covenant>>(
      `${this.url}/${id}/collaborators?name=${term}&page=1&per_page=10&active=true`,
      {
        responseType: 'json',
      }
    );
  }

  getActiveCollaborators(id: number, term) {
    return this.http.get<Array<Collaborator>>(
      `${this.url}/${id}/collaborators/all-active?name=${term}&page=1&per_page=10&active=true`,
      { responseType: 'json' }
    );
  }

  getCovenantSearch(search: string) {
    const params = {
      search: search,
    };

    return this.http.get(`${this.url}/search`, {
      responseType: 'json',
      params: params,
    });
  }

  save(covenant: Covenant) {
    let dto = new CovenantDTO(covenant);

    return this.http.post(`${this.url}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  update(covenant: Covenant) {
    let dto = new CovenantDTO(covenant);
    return this.http.put(`${this.url}/${covenant.id}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  active(id: number) {
    return this.http.put(`${this.url}/${id}/active`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  deactive(id: number) {
    return this.http.put(`${this.url}/${id}/deactivate`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  getAllActiveCovenants() {
    return this.http.get<Array<Covenant>>(`${this.url}/all-active`, {
      responseType: 'json',
    });
  }

  getAllCovenants() {
    return this.http.get<Array<Covenant>>(`${this.url}/all`, {
      responseType: 'json',
    });
  }

  imageUpload(id: number, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(`${this.url}/${id}/image-upload`, formData);
  }

  activeAccount(value: { token: string; password: string }) {
    return this.http.put(
      `${environment.BASE_URL}/covenant/collaborator/reset_password/token`,
      JSON.stringify(value),
      {
        headers: this.httpHeaders,
        observe: 'response',
      }
    );
  }
}
